import moment from 'moment';

export function currencyFormat(number) {
    if (number == null) {
        return '--';
    }
    const value = sanitizeInput(number);
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });
}

export function percentFormat(number) {
    if (number == null) {
        return '--';
    }
    let value = sanitizeInput(number);
    return (
        value.toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2
        }) + '%'
    );
}

export function floatFormat(number) {
    const value = sanitizeInput(number);
    return +value.toFixed(2);
}

export function dateFormat(date) {
    const unixDate = moment.unix(date);
    return moment.utc(unixDate).format('YYYY-MM-DD');
}

export function dateStringFormat(date) {
    const splitDate = date.slice(0, date.indexOf('T'));
    return splitDate ? splitDate : 'N/A';
}

export function boolFormat(bool) {
    if (bool == null) {
        return 'N/A';
    } else return bool ? 'True' : 'False';
}

function sanitizeInput(input) {
    if (typeof input === 'number') {
        return input;
    } else if (typeof input === 'string') {
        input = input.replace(/[^0-9.-]/g, '');
        const parsed = parseFloat(input);
        return parsed ? parsed : 0;
    } else {
        return 0;
    }
}
