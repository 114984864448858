const options = [15, 50, 100, 150];

export default options;

export const resultsPerPageDropdownOptions = options.map(opt => {
    return {
        value: opt,
        label: opt.toString()
    };
});
