import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import log from 'src/middleware/log';
import userReducer from 'src/store/reducers/user';
import tableReducer from 'src/store/reducers/table';
import appReducer from 'src/store/reducers/app';
import ruleReducer from 'src/store/reducers/rule';

const rootReducer = combineReducers({
    user: userReducer,
    table: tableReducer,
    app: appReducer,
    rule: ruleReducer
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleWare = [thunk];

const modesToAddMiddleware = ['development'];

if (modesToAddMiddleware.includes(process.env.NODE_ENV)) {
    middleWare.push(log);
}

export function createNewStore() {
    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...middleWare))
    );
    return store;
}

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleWare))
);

export default store;
