export default {
    dollar: 0,
    percent: 1
};

export const beneficiaryTypes = {
    salesperson: 0,
    orderSupport: 1,
    other: 3
};
