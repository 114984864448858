import actions from './user';
import appActions from 'src/store/actions/app';
import { appStates } from 'src/constants/appStates';
import { initApp } from 'src/store/actions/appAsyncActions';
import navigation from 'src/util/navigation';

const { clearRoles } = actions;

export function signIn() {
    return async function(dispatch) {
        let auth2 = gapi.auth2.getAuthInstance();

        try {
            dispatch(appActions.setAppState(appStates.loading));
            await auth2.signIn();
            dispatch(initApp());
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(err);
            dispatch(signOut());
        }
    };
}

export async function refreshAuthToken() {
    let auth2 = gapi.auth2.getAuthInstance();
    const authResponse = await auth2.currentUser.get().reloadAuthResponse();
    return authResponse;
}

export function signOut() {
    return async function(dispatch) {
        var auth2 = gapi.auth2.getAuthInstance();
        if (auth2) await auth2.signOut();
        dispatch(appActions.setAppState(appStates.uninitialized));
        await navigation.setContext({ profile: null });
        dispatch(clearRoles());
    };
}
