import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import analyticsPrebuiltTables from 'src/constants/analyticsPrebuiltTables';
import dateRanges from 'src/constants/dateRanges';
import invoiceDateSearchOptions from 'src/constants/invoiceDateSearchOptions';
import getIndividualDays from 'src/util/getIndividualDays';

export default async function(req) {
    let startDate = req.query.startDate;
    let endDate = req.query.endDate;

    // Get analytics fields, convert to a string we can append to the request url
    let availableFieldsUrl = `Bargzalytics/AvailableFields`;
    let availableFields = await store.dispatch(
        getDataWithCachedRoutes(availableFieldsUrl)
    );
    let fieldsPrepend = '&Fields=';
    let allFields = `${fieldsPrepend}${availableFields.data.join(
        `${fieldsPrepend}`
    )}`;

    if (!startDate || !endDate) {
        startDate = dateRanges.defaultDateRange.start;
        endDate = dateRanges.defaultDateRange.end;
        req.query = {
            ...req.query,
            startDate,
            endDate
        };
    }

    let dateSearchOption =
        invoiceDateSearchOptions.find(
            opt => opt.value === req.query.dateSearchOption
        ) || invoiceDateSearchOptions[0];

    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;

    // Get each day between the start and end date, create an array of request urls
    let allDays = getIndividualDays(startDate, endDate);
    let requestUrls = allDays.map(day => {
        return `Bargzalytics/?${dateSearchOption.startValue}=${day}&${dateSearchOption.endValue}=${day}${allFields}`;
    });

    // We don't want to use a cancel token here - it will result in only the last request going through
    let bypassCancelToken = true;
    let allResponses = await Promise.all(
        requestUrls.map(url => {
            return store.dispatch(
                getDataWithCachedRoutes(url, bypassCancelToken)
            );
        })
    );

    // concat the response data arrays
    let data = {
        data: [].concat(
            ...allResponses.map(res => {
                return res.data;
            })
        )
    };

    const queryParams = new QueryParams(req.query);

    if (queryParams.selectedAnalysis) {
        const selectedAnalysis = analyticsPrebuiltTables.find(
            a => a.label === queryParams.selectedAnalysis
        );
        data = { data: data.data, ...selectedAnalysis.value };
    }

    return {
        data,
        queryParams
    };
}
