import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import resultsPerPageOptions from 'src/constants/resultsPerPageOptions';

export default async function(req) {
    const TableRows = (await import('src/util/tableRows')).default;
    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;
    const headers = (await import('src/rootComponents/Salespeople/headers'))
        .default;

    const resultsPerPage =
        req.query.resultsPerPage || resultsPerPageOptions[0].toString();
    req.query = {
        ...req.query,
        resultsPerPage
    };

    if (!req.query.sort) {
        const defaultSort = headers.find(header => header.primaryKey).value;
        const defaultAsc = false;
        req.query = { ...req.query, sort: defaultSort, asc: defaultAsc };
    }

    const flagRules = await store.dispatch(
        getDataWithCachedRoutes(`/InvoiceFlagRule`)
    );

    const queryParams = new QueryParams(req.query);

    const tableRows = new TableRows(
        flagRules.data,
        headers,
        resultsPerPage,
        queryParams
    );

    tableRows.filterBySearch().getSortedRows();

    return {
        tableRows,
        queryParams
    };
}
