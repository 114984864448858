import React from 'react';
import classes from './input.css';
import { string, object } from 'prop-types';
import AlertCircle from 'feather/alert-circle';

function Error(props) {
    const { formState = {}, name } = props;
    return (
        <div className={classes.error}>
            {formState[name] && !formState[name].isValid ? (
                <AlertCircle />
            ) : null}
        </div>
    );
}

Error.propTypes = {
    formState: object,
    name: string
};

function Input(props) {
    const { formState, ...inputProps } = props;
    const { name } = inputProps;
    return (
        <span className={classes.root}>
            <input {...inputProps} />
            <Error formState={formState} name={name} />
        </span>
    );
}

Input.propTypes = {
    name: string,
    formState: object
};

export default Input;
