import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import overrideStatusDisplayAll from 'src/constants/overrideStatusDisplayAll';
import store from 'src/store';

export default async function getOverrideStatusDetail() {
    const statusRequestURL = '/CommissionPayoutOverride/DispositionTypes';
    const statusQuery = await store.dispatch(
        getDataWithCachedRoutes(statusRequestURL)
    );
    const statuses = statusQuery.data.map(item => {
        return {
            value: item.id,
            label: item.name,
            description: item.description
        };
    });
    statuses.push(overrideStatusDisplayAll);

    return statuses;
}
