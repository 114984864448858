import actions from '../actions/table';
import { handleActions } from 'redux-actions';
const isEqual = require('lodash/isEqual');

export const tableInitialState = {
    selectedRow: null,
    pendingRows: []
};

const reducerMap = {
    [actions.setSelectedRow]: (state, action) => {
        return { ...state, selectedRow: action.payload };
    },
    [actions.addPendingRow]: (state, action) => {
        const newRows = Array.from(state.pendingRows);
        newRows.push(action.payload);
        return { ...state, pendingRows: newRows };
    },
    [actions.removePendingRow]: (state, action) => {
        const newRows = Array.from(state.pendingRows);
        const filteredRows = newRows.filter(
            newRow => !isEqual(newRow, action.payload)
        );
        return { ...state, pendingRows: filteredRows };
    }
};

export default handleActions(reducerMap, tableInitialState);
