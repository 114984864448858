import { cancelRequest } from 'src/store/actions/appAsyncActions';
import axios from 'axios';
import actions from 'src/store/actions/app';
import store from 'src/store';
import ErrorNetwork from 'src/components/ErrorNetwork';
import defaultRequestInterceptor from './defaultRequestInterceptor';
import { apiUrl } from './apiUrl';

export const routerRequest = axios.create({
    baseURL: apiUrl,
    onDownloadProgress: progressEvent => {
        let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
        );
        store.dispatch(actions.setDownloadProgress(percentCompleted));
    }
});

routerRequest.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if (axios.isCancel(error)) {
            return null;
        }
        error.component = ErrorNetwork;
        store.dispatch(
            actions.requestError({
                message: error.message,
                request: error.request
            })
        );
        return Promise.reject(error);
    }
);

routerRequest.interceptors.request.use(
    defaultRequestInterceptor(config => {
        store.dispatch(actions.setDownloadProgress(0));
        return config;
    }),
    function(error) {
        return Promise.reject(error);
    }
);

export const getRouteData = (url, config, bypassCancelToken) => {
    store.dispatch(cancelRequest());
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // TODO: Look into keeping the cancel token in the
    // axios instance
    if (!bypassCancelToken) {
        store.dispatch(actions.setCancelToken(source));
    }
    const sharedConfig = {
        ...config,
        cancelToken: source.token
    };
    return routerRequest.get(url, sharedConfig);
};
