import { map, mount, route, redirect } from 'navi';
import { pageRoutes } from 'src/routes/pageRoutes';
import { navMenuLinks } from 'src/constants/sidebarItems';

const routes = mount({
    '*': map(async (request, context) => {
        if (context.uninitialized) {
            return redirect(
                `${PUBLIC_URL}/?redirectTo=` +
                    encodeURIComponent(request.originalUrl),
                { exact: false }
            );
        }

        return !context.profile
            ? redirect(
                  `${PUBLIC_URL}/login?redirectTo=` +
                      encodeURIComponent(request.originalUrl),
                  { exact: false }
              )
            : pageRoutes;
    }),
    // Catches the root URL before login to avoid adding 'redirectTo' by default
    '/': map(async (request, context) => {
        if (context.uninitialized) {
            return route({
                title: 'Loading | Commissions',
                getView: () => import('src/components/LoadingSpinner')
            });
        }
        const params = request.params.redirectTo
            ? decodeURIComponent(request.params.redirectTo)
            : null;

        let defaultRedirect = '/invoices/';

        if (context.profile) {
            // get the top role a user has
            const topRole = context.profile.roles[0];
            // grab the first url link from that role's nav menu links
            const topNavLink = navMenuLinks[topRole][0].url;
            defaultRedirect = topNavLink;
        }

        return !context.profile
            ? params
                ? redirect(`${PUBLIC_URL}/login?redirectTo=` + params)
                : redirect(`${PUBLIC_URL}/login`)
            : params
            ? redirect(params)
            : redirect(`${PUBLIC_URL}${defaultRedirect}`);
    }),
    '/login': map(async (request, context) => {
        return !context.profile
            ? route({
                  title: 'Login | Commissions',
                  getView: () => import('src/rootComponents/Login')
              })
            : redirect(
                  request.params.redirectTo
                      ? decodeURIComponent(request.params.redirectTo)
                      : PUBLIC_URL || '/'
              );
    })
});

export default routes;
