import { useState } from 'react';
import * as clipboard from 'clipboard-polyfill';
// Returns an array of copied items
// and a function to copy to clipboard
export default function useCopy() {
    const [copied, setCopied] = useState([]);

    function displayCopied(text) {
        setCopied(copied => [...copied, text]);
        setTimeout(() => {
            setCopied(copied => {
                return copied.filter(value => value !== text);
            });
        }, 800);
    }

    async function copyToClipBoard(value) {
        try {
            await clipboard.writeText(value);
            displayCopied(value);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }
    return [copied, copyToClipBoard];
}
