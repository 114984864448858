import { request } from 'src/util/request';
import messageTypes from 'src/constants/messageTypes';
import userRoles from 'src/constants/userRoles';
import appActions from 'src/store/actions/app';
import store from 'src/store';

export function gapiInit() {
    return new Promise((resolve, reject) => {
        try {
            gapi.load('auth2', async () => {
                const auth2 = await gapi.auth2.init({
                    client_id:
                        '980314085195-uo49v2ta8063n8err2c9fagcflsbltlk.apps.googleusercontent.com',
                    scope:
                        'email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email'
                });
                const isSignedIn = auth2.isSignedIn.get();
                if (isSignedIn) {
                    const profile = await getGapiProfile();
                    resolve(profile);
                } else {
                    resolve(null);
                }
            });
        } catch (err) {
            reject(err);
        }
    });
}

export async function getGapiProfile() {
    const auth2 = gapi.auth2.getAuthInstance();
    let profile = auth2.currentUser.get().getBasicProfile();

    const idToken = auth2.currentUser.get().getAuthResponse().id_token;

    profile.idToken = idToken;
    profile.id = profile.getId();

    try {
        let res = await getUserInfo(idToken);
        const {
            isSalesperson,
            isGM,
            isPayroll,
            isAdmin,
            isOrderSupportManager
        } = res.data;
        profile.roles = [];
        if (isAdmin) profile.roles.push(userRoles.admin);
        if (isPayroll) profile.roles.push(userRoles.payroll);
        if (isGM) profile.roles.push(userRoles.gm);
        if (isSalesperson) profile.roles.push(userRoles.sales);
        if (isOrderSupportManager)
            profile.roles.push(userRoles.orderSupportManager);
        profile = {
            ...profile,
            ...res.data
        };
        return profile;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err);
        const newMessage = {
            type: messageTypes.ERROR,
            message: `There was a problem logging in. If this error persists, please report a bug.`
        };
        store.dispatch(appActions.addMessage(newMessage));
        return null;
    }
}

function getUserInfo(idToken) {
    return request(`User/Current`, {
        headers: { Authorization: `Bearer ${idToken}` }
    });
}
