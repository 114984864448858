import { overrideDateRanges } from 'src/constants/dateRanges';
import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import getOverrideStatusDetail from 'src/util/getOverrideStatusDetail';
import userRoles from 'src/constants/userRoles';
import resultsPerPageOptions from 'src/constants/resultsPerPageOptions';
import getHeaderString from 'src/util/getHeaderString';

const { defaultDateRange } = overrideDateRanges;

async function getHeaders() {
    const roles = store.getState().user.roles;
    const { gmOverrideHeaders, salesOverrideHeaders } = await import(
        'src/rootComponents/Overrides/headers'
    );
    return roles.includes(userRoles.gm) ||
        roles.includes(userRoles.admin) ||
        roles.includes(userRoles.orderSupportManager)
        ? gmOverrideHeaders
        : salesOverrideHeaders;
}

export default async function(req, context) {
    const TableRows = (await import('src/util/tableRows')).default;
    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;
    const statuses = await getOverrideStatusDetail();
    const OverrideHeaders = await getHeaders();
    const resultsPerPage =
        req.query.resultsPerPage || resultsPerPageOptions[0].toString();
    req.query = {
        ...req.query,
        resultsPerPage
    };

    let startDate = req.query.startDate;
    let endDate = req.query.endDate;
    if (!startDate || !endDate) {
        startDate = defaultDateRange.start;
        endDate = defaultDateRange.end;
        req.query = {
            ...req.query,
            startDate,
            endDate
        };
    }

    const localHeaders = localStorage.getItem('overrideHeaders');

    if (!req.query.headers) {
        const headerString =
            localHeaders ||
            getHeaderString(
                OverrideHeaders.filter(header => {
                    return header.static == null && !header.defaultHidden;
                })
            );
        req.query = { ...req.query, headers: headerString };
    }

    localStorage.setItem('overrideHeaders', req.query.headers);

    if (!req.query.sort) {
        const defaultSort = OverrideHeaders.find(header => header.primaryKey)
            .value;
        const defaultAsc = false;
        req.query = { ...req.query, sort: defaultSort, asc: defaultAsc };
    }
    let beneficiaryUserId =
        context.profile.roles.includes(userRoles.sales) &&
        context.profile.roles.length === 1
            ? '&BeneficiaryUserId=' + context.profile.email
            : '';
    const overrideRequestURL = `/CommissionPayoutOverride/GetOverrideDetails?CreateDateStartUnix=${startDate}&CreateDateEndUnix=${endDate}${beneficiaryUserId}`;
    const overrideQuery = await store.dispatch(
        getDataWithCachedRoutes(overrideRequestURL)
    );

    const overrides = overrideQuery.data.map(row => {
        const dispositionDetail = statuses[row.disposition];
        return {
            ...row,
            dispositionDetail
        };
    });

    const queryParams = new QueryParams(req.query);

    const tableRows = new TableRows(
        overrides,
        OverrideHeaders,
        resultsPerPage,
        queryParams
    );

    tableRows
        .filterByOverrideStatus()
        .filterBySearch()
        .getSortedRows();

    return {
        tableRows,
        statuses,
        context,
        OverrideHeaders,
        queryParams
    };
}
