export default [
    {
        value: 'paidDate',
        startValue: 'invoicePaidDateStartUnix',
        endValue: 'invoicePaidDateEndUnix',
        label: 'Invoice Paid Date'
    },
    {
        value: 'creationDate',
        startValue: 'invoiceDateStartUnix',
        endValue: 'invoiceDateEndUnix',
        label: 'Invoice Creation Date'
    }
];
