export default function PromiseWithStatus(promise) {
    // Don't modify any promise that has been already modified.
    if (promise.isResolved) return promise;

    // Set initial state
    let isPending = true;
    let isRejected = false;
    let isFulfilled = false;

    // Observe the promise, saving the fulfillment in a closure scope.
    let result = promise.then(
        function(value) {
            isFulfilled = true;
            isPending = false;
            return value;
        },
        function(error) {
            isRejected = true;
            isPending = false;
            throw error;
        }
    );

    result.isFulfilled = function() {
        return isFulfilled;
    };
    result.isPending = function() {
        return isPending;
    };
    result.isRejected = function() {
        return isRejected;
    };
    return result;
}
