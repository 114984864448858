import { createBrowserNavigation } from 'navi';
import store from 'src/store';
import { confirmRouteMessage } from 'src/components/Dialogue/messages';
import actions from 'src/store/actions/app';
import routes from 'src/routes';

let navigation = createBrowserNavigation({
    routes: routes,
    basename: PUBLIC_URL,
    trailingSlash: 'add',
    context: {
        uninitialized: true,
        profile: {}
    }
});

var origNav = navigation.navigate;

navigation.navigate = async function() {
    let argument = arguments[0];
    // Convert string argument to object
    if (typeof argument === 'string') {
        argument = {
            pathname: argument
        };
    }
    if (
        // Don't add public_path if it's already added
        !argument.pathname.includes(PUBLIC_URL) &&
        // Ensure relative urls still work
        argument.pathname[0] === '/'
    ) {
        // Don't use the same object reference
        argument = { ...argument };
        argument.pathname = PUBLIC_URL + argument.pathname;
    }
    checkIfUrlValid(argument);
    const confirmRouteChange = store.getState().app.confirmRouteChange;
    if (confirmRouteChange && !argument.ignoreConfirmRouteChange) {
        store.dispatch(
            actions.setConfirmationDialogue(confirmRouteMessage(argument))
        );
        return;
    }
    arguments[0] = argument;
    await origNav.apply(navigation, arguments);
};

function checkIfUrlValid(args) {
    try {
        if (args.pathname) {
            if (args.pathname[args.pathname.length - 1] !== '/') {
                // eslint-disable-next-line no-console
                console.error('Add a / to the end of your navigation call');
            }
        } else if (typeof args === 'string') {
            if (args[args.length - 1] !== '/') {
                // eslint-disable-next-line no-console
                console.error('Add a / to the end of your navigation call');
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
}

export default navigation;
