import React from 'react';
import { mount, route, compose, withView } from 'navi';
import { getRouteData } from 'src/util/request';
import getHomeProps from './routeFunctions/home';
import getAnalyticsProps from './routeFunctions/analytics';
import getOverridesProps from './routeFunctions/overrides';
import getFlaggedProps from './routeFunctions/flagged';
import getInvoiceProps from './routeFunctions/invoice';
import getCommissionsRouteData from './routeFunctions/commissions';
import getCommissionsBranchSummaryRouteData from './routeFunctions/commissionsBranchSummary';
import getPayoutDetailProps from './routeFunctions/payoutDetails';
import getSalespeopleProps from './routeFunctions/salespeople';
import getFlagRulesProps from './routeFunctions/flagRules';
import getCommissionRuleProps from './routeFunctions/commissionRules';
import getVendorSpiffsProps from './routeFunctions/vendorSpiffs';
import getBranchSettingsProps from './routeFunctions/branchSettings';
import getProjectsProps from './routeFunctions/projects';
import getProjectProps from './routeFunctions/project';
import appTitle from 'src/constants/appTitle';
import profileData from 'src/mocks/mockProfile';
import NotFound from 'src/routes/routeFunctions/notFound';
import userRoles from 'src/constants/userRoles';

export const pageRoutes = compose(
    withView(() => import('src/components/MainLayout')),
    mount({
        '/commission-rules': route(async (req, context) => {
            if (context.profile.roles.includes(userRoles.admin)) {
                const commissionRuleProps = await getCommissionRuleProps(
                    req,
                    context
                );
                const { default: CommissionRules } = await import(
                    'src/rootComponents/CommissionRules'
                );
                return {
                    title: `Commission Rules ${appTitle}`,
                    view: <CommissionRules {...commissionRuleProps} />
                };
            } else return NotFound();
        }),
        '/commission-rules/tree': route(async (request, context) => {
            if (context.profile.roles.includes(userRoles.admin)) {
                const id = request.query.id;
                const { default: RuleTree } = await import(
                    'src/rootComponents/RuleTree'
                );
                const data = await getRouteData(`/CommissionRuleTree/${id}`);

                return {
                    title: `Rule #${id} ${appTitle}`,
                    view: <RuleTree data={data.data} />
                };
            } else return NotFound();
        }),
        '/flag-rules': route(async (req, context) => {
            if (context.profile.roles.includes(userRoles.admin)) {
                const flagRulesProps = await getFlagRulesProps(req, context);
                const { default: FlagRules } = await import(
                    'src/rootComponents/FlagRules'
                );

                return {
                    title: `Flag Rules ${appTitle}`,
                    view: <FlagRules {...flagRulesProps} />
                };
            } else return NotFound();
        }),
        '/flag-rules/tree': route(async (request, context) => {
            if (context.profile.roles.includes(userRoles.admin)) {
                const id = request.query.id;
                const { default: FlagTree } = await import(
                    'src/rootComponents/FlagTree'
                );
                const data = await getRouteData(`/InvoiceFlagRule/${id}`);

                return {
                    title: `Flag #${id} ${appTitle}`,
                    view: <FlagTree data={data.data} />
                };
            } else return NotFound();
        }),
        '/flagged': route(async (req, context) => {
            if (
                context.profile.roles.includes(userRoles.gm) ||
                context.profile.roles.includes(userRoles.admin) ||
                context.profile.roles.includes(userRoles.orderSupportManager) ||
                context.profile.roles.includes(userRoles.sales)
            ) {
                const props = await getFlaggedProps(req, context);
                const { default: Flagged } = await import(
                    'src/rootComponents/Flagged'
                );
                return {
                    title: `Flagged Invoices ${appTitle}`,
                    view: <Flagged {...props} />
                };
            } else return NotFound();
        }),
        '/salespeople': route(async (req, context) => {
            if (
                context.profile.roles.includes(userRoles.admin) ||
                context.profile.roles.includes(userRoles.payroll)
            ) {
                const salespeopleProps = await getSalespeopleProps(
                    req,
                    context
                );
                const { default: Salespeople } = await import(
                    'src/rootComponents/Salespeople'
                );
                return {
                    title: `Salespeople ${appTitle}`,
                    view: <Salespeople {...salespeopleProps} />
                };
            }
        }),
        '/commissions': route(async (req, context) => {
            const commissionsProps = await getCommissionsRouteData(
                req,
                context
            );
            const { default: Commissions } = await import(
                'src/rootComponents/Commissions'
            );
            return {
                title: `Commissions ${appTitle}`,
                view: <Commissions {...commissionsProps} />
            };
        }),
        '/commissions/branch-summary': route(async (req, context) => {
            if (
                context.profile.roles.includes(userRoles.admin) ||
                context.profile.roles.includes(userRoles.gm) ||
                context.profile.roles.includes(userRoles.payroll) ||
                context.profile.roles.includes(userRoles.orderSupportManager)
            ) {
                const commissionsBranchSummaryProps = await getCommissionsBranchSummaryRouteData(
                    req,
                    context
                );
                const { default: CommissionsBranchSummary } = await import(
                    'src/rootComponents/CommissionsBranchSummary'
                );
                return {
                    title: `Commissions By Branch ${appTitle}`,
                    view: (
                        <CommissionsBranchSummary
                            {...commissionsBranchSummaryProps}
                        />
                    )
                };
            } else return NotFound();
        }),
        '/reports': route(async () => {
            const { default: Reports } = await import(
                'src/rootComponents/Reports'
            );
            // TODO: Get data from endpoint
            return {
                title: `Reports ${appTitle}`,
                view: <Reports data={profileData} />
            };
        }),
        '/overrides': route(async (req, context) => {
            const props = await getOverridesProps(req, context);
            const { default: Overrides } = await import(
                'src/rootComponents/Overrides'
            );

            return {
                title: `Overrides ${appTitle}`,
                view: <Overrides {...props} />
            };
        }),
        '/invoices/invoice': route(async req => {
            const props = await getInvoiceProps(req);
            const { default: InvoiceDetail } = await import(
                'src/rootComponents/Invoice'
            );
            return {
                title: `Invoice Detail ${appTitle}`,
                view: <InvoiceDetail {...props} />
            };
        }),
        '/commissions/payout': route(async req => {
            const props = await getPayoutDetailProps(req);
            const { default: PayoutDetail } = await import(
                'src/rootComponents/PayoutDetail'
            );
            return {
                title: `Payout Details ${appTitle}`,
                view: <PayoutDetail {...props} />
            };
        }),
        '/invoices': route(async (req, context) => {
            const props = await getHomeProps(req, context);
            const { default: Home } = await import('src/rootComponents/Home');

            return {
                title: `Invoices ${appTitle}`,
                view: <Home {...props} />
            };
        }),
        '/analytics': route(async (req, context) => {
            const props = await getAnalyticsProps(req, context);
            const { default: Analytics } = await import(
                'src/rootComponents/Analytics'
            );

            return {
                title: `Analytics ${appTitle}`,
                view: <Analytics {...props} />
            };
        }),
        '/vendor-spiffs': route(async (req, context) => {
            const props = await getVendorSpiffsProps(req, context);
            const { default: VendorSpiffs } = await import(
                'src/rootComponents/VendorSpiffs'
            );

            return {
                title: `Vendor SPIFFs ${appTitle}`,
                view: <VendorSpiffs {...props} />
            };
        }),
        '/branch-settings': route(async (req, context) => {
            if (context.profile.roles.includes(userRoles.admin)) {
                const props = await getBranchSettingsProps(req);
                const { default: BranchSettings } = await import(
                    'src/rootComponents/BranchSettings'
                );

                return {
                    title: 'Branch Settings',
                    view: <BranchSettings {...props} />
                };
            } else return NotFound();
        }),
        '/projects': route(async (req, context) => {
            const props = await getProjectsProps(req, context);

            const { default: Projects } = await import(
                'src/rootComponents/Projects'
            );

            return {
                title: 'Projects',
                view: <Projects {...props} />
            };
        }),
        '/projects/project': route(async (req, context) => {
            const props = await getProjectProps(req, context);

            const { default: Project } = await import(
                'src/rootComponents/Project'
            );

            return {
                title: 'Project',
                view: <Project {...props} />
            };
        }),
    })
);
