import { createActions } from 'redux-actions';

const prefix = 'TABLE';

const actionTypes = [
    'SET_SELECTED_ROW',
    'ADD_PENDING_ROW',
    'REMOVE_PENDING_ROW'
];

export default createActions(...actionTypes, { prefix });
