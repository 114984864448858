import React from 'react';
import useFormValidation from 'src/util/useFormValidation';
import validationRules from './validationRules';
import amountType from 'src/constants/overrideAmountType';
const { dollar } = amountType;
import { number, object } from 'prop-types';
import { approveOverride } from 'src/store/actions/tableAsyncActions';
import { useDispatch } from 'react-redux';
import OverrideAmountForm from './overrideAmountForm';
import actions from 'src/store/actions/app';

function ApproveOverride(props) {
    const { selectedRow, requestedValue } = props;
    let [currentState, setState, formState] = useFormValidation(
        {
            value: requestedValue,
            type: dollar,
            comments: ''
        },
        validationRules
    );

    const dispatch = useDispatch();

    const onSubmit = () => {
        let payload = {
            dispositionAmount: parseFloat(currentState.value),
            requestedAmountType: currentState.type,
            dispositionExplanation: currentState.comments,
            row: selectedRow
        };
        dispatch(approveOverride(payload));
        dispatch(actions.setConfirmationDialogue(null));
    };

    const onClose = () => {
        dispatch(actions.setConfirmationDialogue(null));
    };

    const title = 'Approve Override';
    const label = 'Granted';

    function setType(newType) {
        setState(state => {
            return { ...state, type: newType };
        });

        const value = parseFloat(currentState.value);
        const type = newType;
        const grossProfitAmount = selectedRow.grossProfitAmount;

        // If grossProfit == 0, just display current percentage/dollar amount to avoid error
        if (type === amountType.percent) {
            const percent =
                grossProfitAmount === 0
                    ? selectedRow.requestedPercentageAmount
                    : (value / grossProfitAmount) * 100;
            onChange(percent.toFixed(2).toString());
        } else {
            const newValue =
                grossProfitAmount === 0
                    ? selectedRow.requestedDollarAmount
                    : (value * grossProfitAmount) / 100;
            onChange(newValue.toFixed(2).toString());
        }
    }

    function onChange(input) {
        setState(state => {
            return { ...state, value: input };
        });
    }

    function onTextInput(input) {
        setState(state => ({ ...state, comments: input }));
    }

    function onFocus(event) {
        event.target.select();
    }

    return (
        <OverrideAmountForm
            value={currentState.value}
            setType={setType}
            type={currentState.type}
            notes={selectedRow.requestNotes}
            onChange={onChange}
            title={title}
            onTextInput={onTextInput}
            label={label}
            onSubmit={onSubmit}
            onFocus={onFocus}
            onClose={onClose}
            selectedRow={selectedRow}
            formState={formState}
            requestedAmount={selectedRow.dispositionAmount}
            allowComments={true}
            {...props}
        />
    );
}

ApproveOverride.propTypes = {
    requestedValue: number,
    selectedRow: object
};

export default ApproveOverride;
