import moment from 'moment';

export default function getIndividualDays(startDate, endDate) {
    var dates = [];

    var currDate = moment.unix(startDate);
    var lastDate = moment.unix(endDate);

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(
            currDate
                .clone()
                .unix()
                .toString()
        );
    }

    dates.unshift(startDate);

    if (startDate !== endDate) dates.push(endDate);

    return dates;
}
