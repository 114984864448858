import tableActions from 'src/store/actions/table';
import appActions from 'src/store/actions/app';
import {
    closeOverride,
    deleteOverrideConfirmation,
    declineOverrideConfirmation,
    approveOverrideMessage,
    lockPayPeriodConfirmation,
    viewNotesMessage
} from 'src/components/Dialogue/messages.js';
import { request } from 'src/util/request';
import {
    generateRequestedOverrideMessage,
    generateCreatedOverrideMessage,
    generateApproveOverrideMessage
} from 'src/util/getOverrideRequestMessage';
import messageTypes from 'src/constants/messageTypes';
import { appStates } from 'src/constants/appStates';
import navigation from 'src/util/navigation';
import getConvertedDispositionAmount from 'src/util/getConvertedDispositionAmount';
import safeAccessInvoice from 'src/util/safeAccessInvoice';
import { saveAs } from 'file-saver';

const {
    setConfirmationDialogue,
    removeCachedRoutes,
    addMessage,
    openOverride,
    setAppState
} = appActions;
const { addPendingRow, removePendingRow, setSelectedRow } = tableActions;

export function createOverride(payload) {
    return async function(dispatch) {
        const {
            amount,
            type,
            invoiceNumber,
            orderNumber,
            beneficiaryUserId,
            dispositionNotes,
            row,
            beneficiaryType
        } = payload;

        const requestBody = {
            dispositionAmount: getConvertedDispositionAmount(amount, type),
            amountType: type,
            invoiceNumber,
            orderNumber,
            beneficiaryUserId,
            dispositionNotes,
            beneficiaryType
        };

        async function postCreate() {
            await request.post('CommissionPayoutOverride/Create', requestBody);
        }
        const newMessage = {
            type: messageTypes.SUCCESS,
            message: generateCreatedOverrideMessage({
                ...requestBody,
                dispositionAmount: amount
            })
        };
        await processOverride(postCreate, dispatch, newMessage, row);
    };
}

export function requestOverride(payload) {
    return async function(dispatch) {
        const {
            amount,
            type,
            invoiceNumber,
            orderNumber,
            beneficiaryUserId,
            requestNotes,
            row
        } = payload;

        const requestBody = {
            requestedAmount: getConvertedDispositionAmount(amount, type),
            requestedAmountType: type,
            invoiceNumber,
            beneficiaryUserId,
            orderNumber,
            requestNotes
        };
        async function postRequest() {
            await request.post('CommissionPayoutOverride/Request', requestBody);
        }
        const newMessage = {
            type: messageTypes.SUCCESS,
            message: generateRequestedOverrideMessage({
                ...requestBody,
                requestedAmount: amount
            })
        };
        await processOverride(postRequest, dispatch, newMessage, row);
    };
}

async function processOverride(overridePost, dispatch, message, row) {
    try {
        // Add the pending style, deselect the row, and close the override
        dispatch(addPendingRow(row));
        dispatch(setSelectedRow(null));
        dispatch(appActions.closeOverride());

        await overridePost();

        // Remove cached routes and navigate to get new table date
        dispatch(removeCachedRoutes());
        navigateToSameRoute(dispatch);
        // After, add the success message
        dispatch(addMessage(message));
        // Remove the pending row style
        dispatch(removePendingRow(row));
    } catch {
        // On failure, remove the pending style, select the row, and open the override again
        dispatch(removePendingRow(row));
        dispatch(setSelectedRow(row));
        dispatch(openOverride());
    }
}

export function initOverride(row) {
    return function(dispatch, getState) {
        const { table } = getState();
        const { app } = getState();
        if (!app.isOverrideOpen) {
            dispatch(openOverride());
        }
        if (!table.selectedRow) {
            dispatch(setSelectedRow(row));
        } else {
            dispatch(
                setConfirmationDialogue({
                    ...closeOverride(row)
                })
            );
        }
    };
}

async function navigateToSameRoute(dispatch) {
    // Remove cached routes, then navigate to call the page route request again, which reloads the table
    dispatch(removeCachedRoutes());
    const currentRoute = navigation.getCurrentValue();
    currentRoute.url.ignoreConfirmRouteChange = true;
    await navigation.navigate(currentRoute.url);
    dispatch(setAppState(appStates.initalized));
}

export function approveOverrideInit(requestedDollarAmount, row) {
    return async function(dispatch) {
        let message = approveOverrideMessage(
            approveOverride,
            requestedDollarAmount,
            row
        );
        dispatch(setConfirmationDialogue(message));
    };
}

export function viewNotes(row) {
    return async function(dispatch) {
        let message = viewNotesMessage(row);
        dispatch(setConfirmationDialogue(message));
    };
}

export function approveOverride(payload) {
    return async function(dispatch) {
        const {
            row,
            dispositionAmount,
            requestedAmountType,
            dispositionExplanation
        } = payload;
        try {
            dispatch(addPendingRow(row));

            const payload = {
                dispositionAmount: getConvertedDispositionAmount(
                    dispositionAmount,
                    requestedAmountType
                ),
                requestedAmountType,
                disposition: 1,
                dispositionExplanation: dispositionExplanation,
                commissionPayoutOverrideId: row.id,
                invoiceNumber: row.invoiceNumber
            };

            await request.post(
                'CommissionPayoutOverride/ApproveOrDecline',
                payload
            );

            let newMessage = {
                type: messageTypes.SUCCESS,
                message: generateApproveOverrideMessage({
                    ...payload,
                    dispositionAmount: dispositionAmount
                })
            };

            await navigateToSameRoute(dispatch);
            dispatch(addMessage(newMessage));
            dispatch(removePendingRow(row));
        } catch {
            dispatch(removePendingRow(row));
        }
    };
}

export function declineOverride(commissionPayoutOverrideId, row) {
    return async function(dispatch) {
        const declineOverrideRequest = async notes => {
            const payload = {
                disposition: 3,
                commissionPayoutOverrideId,
                dispositionExplanation: notes,
                requestedAmountType: 0,
                dispositionAmount: 0
            };

            try {
                dispatch(setConfirmationDialogue(null));
                dispatch(addPendingRow(row));
                await request.post(
                    'CommissionPayoutOverride/ApproveOrDecline',
                    payload
                );
                const newMessage = {
                    type: messageTypes.SUCCESS,
                    message: `Override declined`
                };

                await navigateToSameRoute(dispatch);
                // Wait until navigation is completed before showing success message
                dispatch(addMessage(newMessage));
                dispatch(removePendingRow(row));
            } catch {
                dispatch(removePendingRow(row));
                dispatch(setConfirmationDialogue(null));
            }
        };
        let message = declineOverrideConfirmation(declineOverrideRequest, row);
        dispatch(setConfirmationDialogue(message));
    };
}

export function deleteOverride(id, row) {
    return async function(dispatch) {
        const deleteRequest = async () => {
            try {
                dispatch(addPendingRow(row));
                dispatch(setConfirmationDialogue(null));
                await request.delete('CommissionPayoutOverride/' + id);
                const newMessage = {
                    type: messageTypes.SUCCESS,
                    message: 'Override request cancelled'
                };
                await navigateToSameRoute(dispatch);

                dispatch(addMessage(newMessage));
            } catch {
                dispatch(setConfirmationDialogue(null));
                dispatch(removePendingRow(row));
            }
            dispatch(removePendingRow(row));
        };

        let message = deleteOverrideConfirmation(deleteRequest);
        dispatch(setConfirmationDialogue(message));
    };
}

export function dismissFlag(row) {
    return async function(dispatch) {
        dispatch(addPendingRow(row));

        let invoiceNumber = safeAccessInvoice(row, 'invoiceNumber');
        let orderNumber = safeAccessInvoice(row, 'orderNumber');

        const payload = {
            invoiceNumber,
            orderNumber,
            dismissed: true
        };

        try {
            dispatch(appActions.setDownloadProgress(0));
            await request.post('InvoiceFlag/DismissOrEngage', payload);
            const newMessage = {
                type: messageTypes.SUCCESS,
                message: `Flags dismissed`
            };

            await navigateToSameRoute(dispatch);
            // Wait until navigation is completed before showing success message
            dispatch(addMessage(newMessage));
            dispatch(removePendingRow(row));
        } catch {
            dispatch(removePendingRow(row));
            dispatch(appActions.setDownloadProgress(100));
        }
        dispatch(removePendingRow(row));
    };
}

export function exportSalesPayPeriod(
    payPeriodId,
    payPeriodDescription,
    beneficiaryUserId
) {
    return async function(dispatch) {
        try {
            dispatch(appActions.setDownloadProgress(0));

            let res = await request.get(
                `Payroll/Export/InvoiceCommission/${payPeriodId}/${beneficiaryUserId}`
            );
            saveAs(
                new Blob([res.data]),
                `${beneficiaryUserId}-${payPeriodDescription}.csv`
            );

            dispatch(appActions.setDownloadProgress(100));
        } catch {
            dispatch(appActions.setDownloadProgress(100));
            dispatch(setConfirmationDialogue(null));
        }
    };
}

export function lockPayPeriod(payPeriodId, branch, row) {
    return async function(dispatch) {
        const lockRequest = async () => {
            try {
                dispatch(addPendingRow(row));
                dispatch(setConfirmationDialogue(null));
                await request.post(
                    `Payroll/LockCommissionSummaries?payPeriodId=${payPeriodId}&branch=${branch}`
                );
                const newMessage = {
                    type: messageTypes.SUCCESS,
                    message: 'Pay period locked'
                };
                await navigateToSameRoute(dispatch);
                dispatch(addMessage(newMessage));
            } catch {
                dispatch(setConfirmationDialogue(null));
                dispatch(removePendingRow(row));
            }
            dispatch(removePendingRow(row));
        };

        let message = lockPayPeriodConfirmation(lockRequest);
        dispatch(setConfirmationDialogue(message));
    };
}

export function validatePayPeriod(payPeriodId) {
    return async function(dispatch) {
        try {
            dispatch(appActions.setDownloadProgress(0));
            let { data } = await request.post(
                `Payroll/Export/Summary/${payPeriodId}/Validate`
            );
            if (!data.isValid) {
                let messages = data.errorMessages.map(message => {
                    return {
                        type: messageTypes.ERROR,
                        message: message.errorMessage
                    };
                });
                // if invalid, generate a message for each error returned
                messages.forEach(message => {
                    dispatch(addMessage(message));
                });
            }
            dispatch(appActions.setDownloadProgress(100));
            return data.isValid;
        } catch {
            dispatch(appActions.setDownloadProgress(100));
            throw new Error();
        }
    };
}

export function exportPayPeriod(payPeriod, paychexClientId) {
    return async function(dispatch) {
        dispatch(appActions.setDownloadProgress(0));
        try {
            let res = await request.get(
                `Payroll/Export/Summary/${payPeriod.value}/${paychexClientId}`
            );
            saveAs(
                new Blob([res.data]),
                `${payPeriod.label}-${paychexClientId}.csv`
            );

            dispatch(appActions.setDownloadProgress(100));
        } catch {
            dispatch(appActions.setDownloadProgress(100));
            dispatch(setConfirmationDialogue(null));
        }
    };
}

export function exportAccruals(payPeriod) {
    return async function(dispatch) {
        dispatch(appActions.setDownloadProgress(0));
        try {
            let res = await request.get(
                `Payroll/Export/Accruals/${payPeriod.value}`
            );
            saveAs(new Blob([res.data]), `Accruals-${payPeriod.label}.csv`);

            dispatch(appActions.setDownloadProgress(100));
        } catch {
            dispatch(appActions.setDownloadProgress(100));
        }
    };
}

export function updateSalesperson(row) {
    return async function(dispatch) {
        try {
            dispatch(addPendingRow(row));
            await request.put(`User/Info/email=${row.userId}`, row);
            const newMessage = {
                type: messageTypes.SUCCESS,
                message: 'User ' + row.userId + ' updated'
            };
            dispatch(removeCachedRoutes());
            await navigateToSameRoute(dispatch);
            dispatch(addMessage(newMessage));
            dispatch(removePendingRow(row));
        } catch {
            dispatch(removePendingRow(row));
        }
    };
}

export function addUser(user) {
    return async function(dispatch) {
        try {
            await request.post(`User/Info`, user);
            const newMessage = {
                type: messageTypes.SUCCESS,
                message: 'User ' + user.userId + ' added'
            };
            await navigateToSameRoute(dispatch);
            dispatch(addMessage(newMessage));
        } catch {
            dispatch(setConfirmationDialogue(null));
        }
    };
}

export function addSpiff(spiff) {
    return async function(dispatch) {
        try {
            await request.post(`VendorSPIFF`, spiff);
            const newMessage = {
                type: messageTypes.SUCCESS,
                message: 'Created Spiff for ' + spiff.beneficiaryUserId
            };
            await navigateToSameRoute(dispatch);
            dispatch(addMessage(newMessage));
        } catch {
            const errorMessage = {
                type: messageTypes.ERROR,
                message:
                    'Spiff Creation Failed! Make sure the selected pay period is not locked for the beneficiary user.'
            };
            dispatch(addMessage(errorMessage));
            dispatch(setConfirmationDialogue(null));
        }
    };
}

export function updateSpiff(spiff) {
    return async function(dispatch) {
        try {
            await request.put(`VendorSPIFF/${spiff.id}`, spiff);
            const newMessage = {
                type: messageTypes.SUCCESS,
                message: 'Updated Spiff for ' + spiff.beneficiaryUserId
            };
            await navigateToSameRoute(dispatch);
            dispatch(addMessage(newMessage));
        } catch {
            dispatch(setConfirmationDialogue(null));
        }
    };
}

export function deleteSpiff(spiff) {
    return async function(dispatch) {
        try {
            await request.delete(`VendorSPIFF/${spiff.id}`);
            const newMessage = {
                type: messageTypes.SUCCESS,
                message: 'Deleted Spiff for ' + spiff.beneficiaryUserId
            };
            await navigateToSameRoute(dispatch);
            dispatch(addMessage(newMessage));
            dispatch(setConfirmationDialogue(null));
        } catch {
            dispatch(setConfirmationDialogue(null));
        }
    };
}

export function printInvoice(invoiceNumber, orderNumber) {
    return async function(dispatch) {
        try {
            dispatch(appActions.setDownloadProgress(0));
            // TODO: use actual endpoint
            let res = await request.get(
                `Invoice/PrintInvoice?invoiceNumber=${invoiceNumber}&orderNumber=${orderNumber}`,
                {
                    method: 'GET',
                    responseType: 'blob' // important
                }
            );

            // Believe it or not this is the reccomended way of getting a PDF attachment
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${invoiceNumber}.pdf`);
            document.body.appendChild(link);
            link.click();

            dispatch(appActions.setDownloadProgress(100));
        } catch {
            dispatch(appActions.setDownloadProgress(100));
        }
    };
}

export function findInvoice(invoiceNumberToSearch, orderNumberToSearch) {
    // Users will pass in either an invoiceNumber or an orderNumber
    // When they pass in one, we make a call to find the other

    return async function(dispatch) {
        const errorMessage = {
            type: messageTypes.ERROR,
            message: invoiceNumberToSearch
                ? `Error finding invoice #${invoiceNumberToSearch}.`
                : `Error finding invoice for order #${orderNumberToSearch}.`
        };

        const url = invoiceNumberToSearch
            ? `Invoice/?InvoiceNumber=${invoiceNumberToSearch}`
            : `Invoice/?OrderNumber=${orderNumberToSearch}`;

        try {
            dispatch(appActions.setDownloadProgress(0));
            let res = await request.get(url);

            // We only want to go to an invoice detail page when exactly one response is found
            if (res.data.length === 1) {
                const invoiceRes = res.data[0];

                const invoiceNumber = invoiceNumberToSearch
                    ? invoiceNumberToSearch
                    : safeAccessInvoice(invoiceRes, 'invoiceNumber');

                const orderNumber = orderNumberToSearch
                    ? orderNumberToSearch
                    : safeAccessInvoice(invoiceRes, 'orderNumber');

                if (invoiceRes && invoiceNumber && orderNumber) {
                    dispatch(appActions.setDownloadProgress(100));

                    await navigation.navigate({
                        pathname: '/invoices/invoice/',
                        query: {
                            id: invoiceNumber,
                            orderNumber: orderNumber
                        }
                    });
                } else {
                    throw Error();
                }
            } else {
                throw Error();
            }
        } catch {
            dispatch(appActions.setDownloadProgress(100));
            dispatch(addMessage(errorMessage));
        }
    };
}

export function updateBranchSettings(row) {
    return async function(dispatch) {
        try {
            await request.put(`BranchSettings/${row.branch}`, row);
            const newMessage = {
                type: messageTypes.SUCCESS,
                message: row.branch + ' branch settings updated'
            };
            dispatch(removeCachedRoutes());
            await navigateToSameRoute(dispatch);
            dispatch(addMessage(newMessage));
            dispatch(removePendingRow(row));
        } catch {
            dispatch(removePendingRow(row));
        }
    };
}

export function addNewBranch(branch) {
    return async function(dispatch) {
        try {
            await request.post(`BranchSettings`, branch);
            const newMessage = {
                type: messageTypes.SUCCESS,
                message: branch.branch + ' branch added'
            };
            await navigateToSameRoute(dispatch);
            dispatch(addMessage(newMessage));
        } catch {
            dispatch(setConfirmationDialogue(null));
        }
    };
}

export function addInvoiceNote(invoiceNumber, orderNumber, comments) {
    return async function(dispatch) {
        try {
            await request.post(`Invoice/${invoiceNumber}/${orderNumber}/Note`, {
                comments: comments
            });

            const newMessage = {
                type: messageTypes.SUCCESS,
                message: 'Note added'
            };
            await navigateToSameRoute(dispatch);
            dispatch(addMessage(newMessage));
        } catch {
            const newMessage = {
                type: messageTypes.ERROR,
                message: 'Failed to add note. Please try again.'
            };
            dispatch(addMessage(newMessage));
            dispatch(setConfirmationDialogue(null));
        }
    };
}

export function deleteBranch(branch) {
    return async function(dispatch) {
        try {
            await request.delete(`BranchSettings/${branch.branch}`);
            const newMessage = {
                type: messageTypes.SUCCESS,
                message: `Deleted ${branch.branch} branch`
            };
            await navigateToSameRoute(dispatch);
            dispatch(addMessage(newMessage));
            dispatch(setConfirmationDialogue(null));
        } catch {
            dispatch(setConfirmationDialogue(null));
        }
    };
}
