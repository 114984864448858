import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import resultsPerPageOptions from 'src/constants/resultsPerPageOptions';

export default async function(req) {
    const DetailHeaders = (
        await import('src/rootComponents/PayoutDetail/headers')
    ).default;
    const TableRows = (await import('src/util/tableRows')).default;
    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;
    const headers = DetailHeaders;
    const { id, branch, userId } = req.query;
    const requestURL = `Payroll/Details?SalespersonBranch=${branch}&BeneficiaryUserId=${userId}&PayPeriodId=${id}`;
    const data = await store.dispatch(getDataWithCachedRoutes(requestURL));

    const resultsPerPage =
        req.query.resultsPerPage || resultsPerPageOptions[0].toString();
    req.query = {
        ...req.query,
        resultsPerPage
    };

    const queryParams = new QueryParams(req.query);

    const commissionOverride = data.data.commissionOverride;

    const commissionPayout = new TableRows(
        data.data.commissionPayout,
        headers,
        resultsPerPage,
        queryParams
    );

    commissionPayout.filterBySearch().getSortedRows();

    return {
        commissionOverride,
        commissionPayout,
        headers,
        queryParams,
        userId,
        branch
    };
}
