import actions from '../actions/app';
import { handleActions } from 'redux-actions';
import messageTypes from 'src/constants/messageTypes';
import { appStates } from 'src/constants/appStates';

export const appInitialState = {
    apiUrl: process.env.API_URL,
    messages: [],
    downloadProgress: 100,
    cancelToken: null,
    confirmRouteChange: false,
    confirmationMessage: null,
    fieldTypes: null,
    appState: appStates.uninitialized,
    isOverrideOpen: false,
    cachedRoutes: {},
    invoiceLetterMap: {},
    payPeriodIds: []
};

const reducerMap = {
    [actions.requestError]: (state, action) => {
        let newArray = state.messages.slice();
        newArray.push({
            ...action.payload,
            type: messageTypes.ERROR
        });
        return { ...state, messages: newArray };
    },
    [actions.dismissMessage]: state => {
        let newArray = state.messages.slice();
        if (newArray.length > 0) {
            newArray.pop();
        }
        return { ...state, messages: newArray };
    },
    [actions.dismissAllMessages]: state => {
        return { ...state, messages: [] };
    },
    [actions.addMessage]: (state, action) => {
        let newArray = state.messages.slice();
        newArray.push({
            type: messageTypes.INFO,
            ...action.payload
        });
        return { ...state, messages: newArray };
    },
    [actions.setDownloadProgress]: (state, action) => {
        return { ...state, downloadProgress: action.payload };
    },
    [actions.setCancelToken]: (state, action) => {
        return { ...state, cancelToken: action.payload };
    },
    [actions.setConfirmationDialogue]: (state, action) => {
        return { ...state, confirmationMessage: action.payload };
    },
    [actions.setConfirmRouteChange]: (state, action) => {
        return { ...state, confirmRouteChange: action.payload };
    },
    [actions.setFieldTypes]: (state, action) => {
        return {
            ...state,
            fieldTypes: action.payload.fieldTypes,
            mappedFieldTypes: action.payload.mappedFieldTypes
        };
    },
    [actions.setInvoiceLetterMap]: (state, action) => {
        return {
            ...state,
            invoiceLetterMap: action.payload
        };
    },
    [actions.setAppState]: (state, action) => {
        return { ...state, appState: action.payload };
    },
    [actions.openOverride]: state => {
        return { ...state, isOverrideOpen: true };
    },
    [actions.closeOverride]: state => {
        return { ...state, isOverrideOpen: false };
    },
    [actions.addCachedRoute]: (state, action) => {
        const newCachedRoutes = state.cachedRoutes;
        const { requestURL, currentRouteRequest } = action.payload;

        newCachedRoutes[requestURL] = currentRouteRequest;
        return { ...state, cachedRoutes: newCachedRoutes };
    },
    [actions.removeCachedRoutes]: state => {
        return { ...state, cachedRoutes: {} };
    }
};

export default handleActions(reducerMap, appInitialState);
