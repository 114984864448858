import { createActions } from 'redux-actions';

const prefix = 'USER';

const actionTypes = [
    'INIT_USER_STORE',
    'SET_ROLES',
    'CLEAR_ROLES',
    'SET_DETAILS'
];

export default createActions(...actionTypes, { prefix });
