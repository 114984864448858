import { useState, useEffect } from 'react';

/* `rules` is a Map where the keys match the keys of the form being validated. The
 * validation functions should return an object with the keys `isValid` and
 * `message`. `message` is only used for an invalid form.
 * Check `TreeForm/validationRules` for an example
 */

export default function(initialForm, rules) {
    const [form, setForm] = useState(initialForm);
    const [formState, setFormState] = useState({ isValid: true, messages: [] });

    useEffect(() => {
        let ruleResults = {};
        let messages = [];
        let isValid = true;
        for (let [key, value] of rules.entries()) {
            const ruleResult = value({ [key]: form[key], form });
            if (!ruleResult.isValid) {
                isValid = false;
                ruleResults[key] = ruleResult;
                // Don't add duplicate messages
                if (!messages.includes(ruleResult.message)) {
                    messages.push(ruleResult.message);
                }
            }
        }
        setFormState({ isValid, messages: messages, ...ruleResults });
    }, [form, rules]);
    return [form, setForm, formState];
}
