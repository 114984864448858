import formstyles from 'src/components/Controllers/form.css';
import React, { useEffect, useState } from 'react';
import classes from './overrideModal.css';
import InputNumber from 'src/components/InputNumber';
import PercentSelect from 'src/components/PercentSelect';
import amountType, { beneficiaryTypes } from 'src/constants/overrideAmountType';
import { any, func, string, number, bool } from 'prop-types';
import { Button, Radio } from 'fronds/Controllers';
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';
import { request } from 'src/util/request';

const { dollar, percent } = amountType;

const populateEmailList = async (setEmailList, setLoading) => {
    const userInfoResponse = await request.get(
        '/User/Info?showTermedEmployees=false'
    );
    const emailList = userInfoResponse.data.map(user => ({
        label: user.userId,
        value: user.userId,
        selected: false
    }));
    setEmailList(emailList);
    setLoading(false);
};

export default function OverrideAmountForm(props) {
    const {
        onSubmit,
        type,
        setType,
        value,
        onChange,
        onTextInput,
        onFocus,
        children,
        formState,
        label,
        onClose,
        onUserSelect,
        notes,
        allowComments,
        isCommentRequired,
        isSalesOnly,
        beneficiaryType,
        setBeneficiaryType,
        canSetOrderSupportBeneficiary,
        canSetSalesBeneficiary
    } = props;

    const [emailList, setEmailList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (onUserSelect) populateEmailList(setEmailList, setIsLoading);

        // Default state does not currently include a property for beneficiary type,
        // so we're resetting to salesperson if the support option is not available
        // It is easy to update default state to fix this, but then the modal would
        // no longer "remember" the last selection.
        // TODO: Investigate which is the desired behavior
        if (
            (!canSetSalesBeneficiary &&
                beneficiaryType === beneficiaryTypes.salesperson) ||
            (!canSetOrderSupportBeneficiary &&
                beneficiaryType === beneficiaryTypes.orderSupport)
        )
            setBeneficiaryType(null);

        if (beneficiaryType === null) {
            if (canSetSalesBeneficiary)
                setBeneficiaryType(beneficiaryTypes.salesperson);
            else if (canSetOrderSupportBeneficiary)
                setBeneficiaryType(beneficiaryTypes.orderSupport);
        }
    }, []);

    return (
        <form
            className={classes.root}
            onSubmit={e => {
                e.preventDefault();
                onSubmit();
            }}
        >
            <div className={classes.textWrapper}>
                {!isSalesOnly ? (
                    <>
                        <div className={classes.radioTitle}>
                            Beneficiary Type
                        </div>
                        <div className={classes.radioWrapper}>
                            <Radio
                                className={
                                    canSetSalesBeneficiary
                                        ? ''
                                        : classes.disabled
                                }
                                name={'beneficiaryType'}
                                value={beneficiaryTypes.salesperson}
                                setValue={newType =>
                                    setBeneficiaryType(newType)
                                }
                                checked={
                                    beneficiaryType ===
                                    beneficiaryTypes.salesperson
                                }
                                disabled={!canSetSalesBeneficiary}
                            >
                                Salesperson
                            </Radio>
                            <Radio
                                className={
                                    canSetOrderSupportBeneficiary
                                        ? ''
                                        : classes.disabled
                                }
                                name={'beneficiaryType'}
                                value={beneficiaryTypes.orderSupport}
                                setValue={newType =>
                                    setBeneficiaryType(newType)
                                }
                                checked={
                                    beneficiaryType ===
                                    beneficiaryTypes.orderSupport
                                }
                                disabled={!canSetOrderSupportBeneficiary}
                            >
                                Order Support
                            </Radio>
                            {onUserSelect && (
                                <>
                                    <Radio
                                        name={'beneficiaryType'}
                                        value={beneficiaryTypes.other}
                                        setValue={newType =>
                                            setBeneficiaryType(newType)
                                        }
                                        checked={
                                            beneficiaryType ===
                                            beneficiaryTypes.other
                                        }
                                    >
                                        Other:
                                    </Radio>
                                    {isLoading ? (
                                        <div className={classes.userListLoader}>
                                            Fetching users...
                                        </div>
                                    ) : (
                                        <DropdownSelectWrapper
                                            listItems={emailList}
                                            onSelectionChange={opt =>
                                                onUserSelect(opt.value)
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </>
                ) : null}
                <div className={classes.radioTitle}>Override Amount Type</div>
                <div className={classes.radioWrapper}>
                    <Radio
                        name={'amountType'}
                        value={dollar}
                        setValue={newType => setType(newType)}
                        checked={type === dollar}
                    >
                        Dollar Amount
                    </Radio>
                    <Radio
                        name={'amountType'}
                        value={percent}
                        setValue={newType => setType(newType)}
                        checked={type === percent}
                    >
                        Percentage of GP
                    </Radio>
                </div>
                <div className={classes.amountWrapper}>
                    <span className={classes.amount}>
                        {type === dollar ? (
                            <InputNumber
                                name="overrideAmount"
                                label={label + ' Amount:'}
                                value={value}
                                onChange={onChange}
                                autoFocus={true}
                                onFocus={onFocus}
                            />
                        ) : (
                            <InputNumber
                                name="overrideAmount"
                                label={label + ' Percent:'}
                                prefix=""
                                suffix="%"
                                value={value}
                                onChange={onChange}
                                autoFocus={true}
                                onFocus={onFocus}
                            />
                        )}
                    </span>
                    {type === percent ? (
                        <PercentSelect
                            setValue={onChange}
                            percentArray={[15, 27, 35]}
                        />
                    ) : null}
                </div>
                {children}
                {notes ? <p>{`Notes: "${notes}"`}</p> : null}
                {allowComments ? (
                    <textarea
                        placeholder={
                            !isCommentRequired
                                ? 'Enter comments (optional)'
                                : 'Enter comments'
                        }
                        name="comments"
                        rows={3}
                        onInput={e => onTextInput(e.target.value)}
                        maxLength={300}
                        required={isCommentRequired}
                    />
                ) : null}
            </div>

            <div className={formstyles.controls}>
                {formState.messages.length > 0 ? (
                    <div className={formstyles.errorMessage}>
                        <p> {formState.messages.join(', ')}</p>
                    </div>
                ) : null}
                <>
                    <Button onClick={onClose}>Close</Button>
                    <Button type="submit" disabled={!formState.isValid}>
                        Submit
                    </Button>{' '}
                </>
            </div>
        </form>
    );
}

OverrideAmountForm.propTypes = {
    onSubmit: func,
    type: number,
    setType: func,
    value: number,
    onChange: func,
    onTextInput: func,
    allowComments: bool,
    isCommentRequired: bool,
    onFocus: func,
    children: any,
    formState: any,
    label: string,
    notes: string,
    onClose: func,
    onUserSelect: func,
    requestedAmount: number,
    isSalesOnly: bool,
    beneficiaryType: number,
    setBeneficiaryType: func,
    canSetOrderSupportBeneficiary: bool,
    canSetSalesBeneficiary: bool
};
