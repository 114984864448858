import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';

export default async function(req) {
    let { BeneficiaryTypes, TreeTypes } = store.getState().app.fieldTypes;

    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;

    const queryParams = new QueryParams(req.query);

    const trees = await store.dispatch(
        getDataWithCachedRoutes('/CommissionRuleTree')
    );

    let { treeType, beneficiaryType } = req.query;

    BeneficiaryTypes = BeneficiaryTypes.map(type => {
        return {
            label: type.description,
            name: type.name,
            value: type.name
        };
    });
    TreeTypes = TreeTypes.map(type => {
        return {
            label: type.description,
            name: type.name,
            value: type.name
        };
    });

    if (!treeType) treeType = TreeTypes[0].value;
    if (!beneficiaryType) beneficiaryType = BeneficiaryTypes[0].value;

    treeType = treeType.toString();
    beneficiaryType = beneficiaryType.toString();

    // Filter trees based on url paramaters
    const filteredRows = trees.data
        .filter(
            tree =>
                tree.commissionRuleTreeType ===
                    TreeTypes.find(type => type.value === treeType).name &&
                tree.beneficiaryType ===
                    BeneficiaryTypes.find(
                        type => type.value === beneficiaryType
                    ).name
        )

        .sort((a, b) => {
            return a.priorityIndex - b.priorityIndex;
        });

    let allGroupCombos = [];

    // For the "Search by name" dropdown - we get all group possibilities
    TreeTypes.forEach(treeType => {
        BeneficiaryTypes.forEach(benType => {
            allGroupCombos.push({
                label: `${treeType.label} - ${benType.label}`,
                treeType: treeType.name,
                benType: benType.name
            });
        });
    });

    // Then we map the trees into their groups, sort them, then map to be readable by the dropdown
    let treeGroups = allGroupCombos.map(group => {
        return {
            label: group.label,
            options: trees.data
                .filter(
                    tree =>
                        tree.commissionRuleTreeType === group.treeType &&
                        tree.beneficiaryType === group.benType
                )
                .sort((a, b) => {
                    return a.priorityIndex - b.priorityIndex;
                })
                .map(tree => {
                    return {
                        label: tree.description,
                        value: tree
                    };
                })
        };
    });

    return {
        trees: trees.data,
        treeGroups,
        queryParams,
        BeneficiaryTypes,
        TreeTypes,
        beneficiaryType,
        treeType,
        filteredRows
    };
}
