import React, { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions/app';
import formstyles from 'src/components/Controllers/form.css';
import { Button } from 'fronds/Controllers';
import classes from './declineOverride.css';

function DeclineOverride(props) {
    const { declineOverrideRequest, row } = props;
    const { requestNotes } = row;
    const [notes, setNotes] = useState('');

    const dispatch = useDispatch();

    const onSubmit = e => {
        e.preventDefault();
        declineOverrideRequest(notes);
    };

    const onClose = () => {
        dispatch(actions.setConfirmationDialogue(null));
    };

    return (
        <div className={classes.root}>
            <form onSubmit={onSubmit}>
                {requestNotes ? <p>{`Notes: "${requestNotes}"`}</p> : null}
                <textarea
                    placeholder={'Enter comments'}
                    name="comments"
                    rows={3}
                    onInput={e => setNotes(e.target.value)}
                    maxLength={300}
                    required={true}
                />
                <div className={formstyles.controls}>
                    <Button onClick={onClose}>Close</Button>
                    <Button type="submit">Submit</Button>
                </div>
            </form>
        </div>
    );
}

DeclineOverride.propTypes = {
    declineOverrideRequest: func,
    row: shape({
        requestNotes: string
    })
};

export default DeclineOverride;
