import React from 'react';
import { Input } from 'src/components/Controllers';

import classes from './inputNumber.css';
import { string, number, func, bool, oneOfType } from 'prop-types';

function InputNumber(props) {
    let {
        prefix = '$',
        suffix = '',
        label,
        value,
        step = 'any',
        onChange,
        readOnly,
        ...restProps
    } = props;

    const isPercent = suffix === '%' ? true : false;
    if (isPercent) prefix = '';

    const inputWrapperClass = readOnly
        ? classes.inputWrapperReadOnly
        : classes.inputWrapper;

    return (
        <label className={classes.inputLabel}>
            <span className={classes.label}>{label}</span>
            <span className={classes.currency}>{prefix} </span>
            <span className={inputWrapperClass}>
                <Input
                    className={classes.input}
                    value={value}
                    onChange={ev => onChange(ev.target.value)}
                    type="number"
                    step={step}
                    {...restProps}
                />
                <span className={classes.percent}>{suffix} </span>
            </span>
        </label>
    );
}

InputNumber.propTypes = {
    prefix: string,
    suffix: string,
    label: string,
    step: oneOfType([string, number]),
    value: number,
    onChange: func,
    readOnly: bool
};

export default InputNumber;
