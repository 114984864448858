import appActions from 'src/store/actions/app';
import userActions from 'src/store/actions/user';
import { request } from 'src/util/request';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import { appStates } from 'src/constants/appStates';
import { gapiInit } from 'src/util/gapiUtil';
import setNewTokenInHeader from 'src/util/request/setNewTokenInHeader';
import navigation from 'src/util/navigation';
import { signOut } from 'src/store/actions/userAsyncActions';
import { getRouteData } from 'src/util/request';

const {
    setAppState,
    setFieldTypes,
    addCachedRoute,
    setInvoiceLetterMap
} = appActions;
const { setRoles, setDetails } = userActions;

export function cancelRequest() {
    return function(dispatch, getState) {
        const {
            app: { cancelToken }
        } = getState();
        if (cancelToken) cancelToken.cancel();
    };
}

export function getDataWithCachedRoutes(requestURL, bypassCancelToken) {
    /*
    Since we are sorting/filtering locally and saving table state in our URL
    we risk a network request on every sort/filter since it prompts a route change.
    Using this function when navigating in pageRoutes will only send a network request when the request is
    - changed via a new request
    - or removed manually via clearCached routes
    */

    // TODO: Add a timeout so after x minutes, we remove a cached route

    return async function(dispatch, getState) {
        const { cachedRoutes } = getState().app;

        let currentRouteRequest = cachedRoutes[requestURL];

        if (!currentRouteRequest) {
            currentRouteRequest = await getRouteData(
                requestURL,
                null,
                bypassCancelToken
            );
            dispatch(addCachedRoute({ requestURL, currentRouteRequest }));
        }

        return currentRouteRequest;
    };
}

export function getInvoiceLetterMap() {
    return async function(dispatch) {
        const data = await request.get('/Invoice/InvoiceLetterMapping');
        let mappedData = Object.assign({}, ...data.data);
        dispatch(setInvoiceLetterMap(mappedData));
    };
}

export function getFieldTypes() {
    return async function(dispatch) {
        const data = [
            await request.get('/CommissionRuleTree/TreeTypes'),
            await request.get('/CommissionRuleTree/BeneficiaryTypes'),
            await request.get('/CommissionRuleTree/NodeTypes')
        ];

        let fieldTypes = data.reduce((accum, req) => {
            const newKey = req.config.url.replace(
                `${req.config.baseURL}CommissionRuleTree/`,
                ''
            );
            accum[newKey] = req.data;
            return accum;
        }, {});

        const mappedFieldTypes = Object.keys(fieldTypes).reduce(
            (accum, curr) => {
                fieldTypes[curr].forEach(value => {
                    accum[value.name] = value;
                });
                return accum;
            },
            {}
        );
        dispatch(setFieldTypes({ fieldTypes, mappedFieldTypes }));
    };
}

async function setupFullstoryUser(profile) {
    if (process.env.NODE_ENV === 'production') {
        FS.identify(profile.id, {
            displayName: `${profile.full_name} | ${profile.roles.join(', ')}`,
            email: profile.email
        });
    }
}

export function initApp() {
    return async function(dispatch) {
        try {
            dispatch(setAppState(appStates.loading));
            const profile = await gapiInit();
            setupFullstoryUser(profile);
            if (profile) {
                await dispatch(setRoles(profile.roles));
                const details = {
                    email: profile.email,
                    name: profile.full_name
                };
                await dispatch(setDetails(details));
                setNewTokenInHeader(profile.idToken);
                await dispatch(getFieldTypes());
                await dispatch(getInvoiceLetterMap());
            }

            navigation.setContext({ profile: profile });
            dispatch(
                setAppState(
                    profile ? appStates.initialized : appStates.uninitialized
                )
            );
        } catch (error) {
            try {
                // Try and print an error with sourcemapped stack trace
                mapStackTrace(error, mappedStack => {
                    // eslint-disable-next-line no-console
                    console.error(`${error}\n${mappedStack.join('\n')}`);
                });
            } catch {
                // eslint-disable-next-line no-console
                console.error(error);
            }
            dispatch(signOut());
        }
    };
}
