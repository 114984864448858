import decodeJWT from 'src/util/jwtUtil';
import { refreshAuthToken } from 'src/store/actions/userAsyncActions';
import setNewTokenInHeader from 'src/util/request/setNewTokenInHeader';
import PromiseWithStatus from 'src/util/promiseWithStatus';

let tokenUpdating = PromiseWithStatus(new Promise(resolve => resolve()));

export default function defaultRequestInterceptor(interceptor) {
    return async config => {
        // Request interceptor runs on every request (even if it's not
        // explicitly being called through  axios). Becuse of this it will fail
        // making a request through  gapi
        try {
            if (config.headers.common.Authorization) {
                return interceptor(await handleTokenInHeader(config));
            } else {
                return interceptor(config);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };
}

async function handleTokenInHeader(config) {
    const {
        payload: { exp }
    } = decodeJWT(config.headers.common.Authorization);
    let expire = Math.floor(exp - Date.now() / 1000);
    if (expire < 0) {
        return await handleExpiredToken(config);
    } else {
        return config;
    }
}

async function handleExpiredToken(config) {
    if (tokenUpdating.isFulfilled() && !tokenUpdating.isPending()) {
        reauthorizeToken();
        const idToken = await waitForTokenAndUpdateConfig(config);
        setNewTokenInHeader(idToken);
        return config;
    } else if (tokenUpdating) {
        await waitForTokenAndUpdateConfig(config);
        return config;
    }
}

async function waitForTokenAndUpdateConfig(config) {
    const idToken = await tokenUpdating;
    config.headers.common.Authorization = `Bearer ${idToken}`;
    return idToken;
}

function reauthorizeToken() {
    tokenUpdating = PromiseWithStatus(
        new Promise(async resolve => {
            const newProfile = await refreshAuthToken();
            resolve(newProfile.id_token);
        })
    );
}
