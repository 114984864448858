import React from 'react';
import classes from './percentSelect.css';
import { func, array } from 'prop-types';
import { Button } from 'fronds/Controllers';

function PercentSelect(props) {
    const { percentArray = [], setValue } = props;

    const selectors = percentArray.map(percent => (
        <Button onClick={() => setValue(percent)} key={percent}>
            {percent + '%'}
        </Button>
    ));
    // value is base value calculating percentage on?
    // setValue sets some value to percentage of initial value

    return <div className={classes.percentWrapper}>{selectors}</div>;
}

PercentSelect.propTypes = {
    percentArray: array,
    setValue: func
};

export default PercentSelect;
