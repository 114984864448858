import { createActions } from 'redux-actions';

const prefix = 'APP';

const actionTypes = [
    'REQUEST_ERROR',
    'ADD_MESSAGE',
    'DISMISS_MESSAGE',
    'DISMISS_ALL_MESSAGES',
    'SET_DOWNLOAD_PROGRESS',
    'SET_CANCEL_TOKEN',
    'SET_CONFIRMATION_DIALOGUE',
    'SET_CONFIRM_ROUTE_CHANGE',
    'SET_FIELD_TYPES',
    'SET_APP_STATE',
    'OPEN_OVERRIDE',
    'CLOSE_OVERRIDE',
    'ADD_CACHED_ROUTE',
    'REMOVE_CACHED_ROUTES',
    'SET_INVOICE_LETTER_MAP'
];

export default createActions(...actionTypes, { prefix });
