import axios from 'axios';
import actions from 'src/store/actions/app';
import store from 'src/store';
import ErrorNetwork from 'src/components/ErrorNetwork';
import defaultRequestInterceptor from './defaultRequestInterceptor';
import { apiUrl } from './apiUrl';

// Generic request to be used in async actions and everything else
export const request = axios.create({
    baseURL: apiUrl
});

request.interceptors.request.use(
    defaultRequestInterceptor(config => {
        return config;
    }),
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response;
    },
    function(error) {
        error.component = ErrorNetwork;
        store.dispatch(
            actions.requestError({
                message: error.response
                    ? error.response.data.error
                    : error.message,
                request: error.request
            })
        );
        return Promise.reject(error);
    }
);
