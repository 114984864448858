import { currencyFormat, percentFormat } from 'src/util/numberFormats';

export function generateRequestedOverrideMessage(payload) {
    const { requestedAmount, requestedAmountType, invoiceNumber } = payload;
    const formattedRequestedAmount =
        requestedAmountType === 0
            ? currencyFormat(requestedAmount)
            : percentFormat(requestedAmount);

    return `${formattedRequestedAmount} override for invoice #${invoiceNumber} requested`;
}

export function generateCreatedOverrideMessage(payload) {
    const { dispositionAmount, amountType, invoiceNumber } = payload;
    const formattedRequestedAmount =
        amountType === 0
            ? currencyFormat(dispositionAmount)
            : percentFormat(dispositionAmount);

    return `${formattedRequestedAmount} override for invoice #${invoiceNumber} created`;
}

export function generateApproveOverrideMessage(payload) {
    const { dispositionAmount, requestedAmountType, invoiceNumber } = payload;
    const formattedRequestedAmount =
        requestedAmountType === 0
            ? currencyFormat(dispositionAmount)
            : percentFormat(dispositionAmount);

    return `${formattedRequestedAmount} override for invoice #${invoiceNumber} granted`;
}
