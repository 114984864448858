import moment from 'moment';

export default {
    today: {
        text: 'Today',
        value: 'today',
        start: moment()
            .startOf('day')
            .unix(),
        end: moment()
            .endOf('day')
            .unix()
    },
    thisWeek: {
        text: 'This Week',
        value: 'thisWeek',
        start: moment()
            .startOf('week')
            .unix(),
        end: moment().unix()
    },
    defaultDateRange: {
        text: 'This Month',
        value: 'thisMonth',
        start: moment()
            .startOf('month')
            .unix(),
        end: moment().unix()
    },
    lastMonth: {
        text: 'Last Month',
        value: 'lastMonth',
        start: moment()
            .startOf('month')
            .subtract(1, 'months')
            .unix(),
        end: moment()
            .subtract(1, 'months')
            .endOf('month')
            .unix()
    }
};

export const overrideDateRanges = {
    today: {
        text: 'Today',
        start: moment()
            .startOf('day')
            .unix(),
        end: moment()
            .endOf('day')
            .unix()
    },
    thisWeek: {
        text: 'This Week',
        start: moment()
            .startOf('week')
            .unix(),
        end: moment()
            .endOf('day')
            .unix()
    },
    thisMonth: {
        text: 'This Month',
        start: moment()
            .startOf('month')
            .unix(),
        end: moment()
            .endOf('day')
            .unix()
    },
    YTD: {
        text: 'YTD',
        start: moment()
            .startOf('year')
            .unix(),
        end: moment()
            .endOf('day')
            .unix()
    },
    defaultDateRange: {
        text: 'Past 60 Days',
        start: moment()
            .add(-60, 'days')
            .startOf('day')
            .unix(),
        end: moment()
            .endOf('day')
            .unix()
    }
};

export const treeFormDefaultDates = {
    initialStartDate: moment()
        .startOf('day')
        .format(),
    initialEndDate: moment()
        .add(2, 'years')
        .endOf('day')
        .format(),
    getStartFormat: (currentDate, datePickerFormat) =>
        moment(currentDate, datePickerFormat)
            .utc()
            .startOf('day')
            .format(),
    getEndFormat: (currentDate, datePickerFormat) =>
        moment(currentDate, datePickerFormat)
            .utc()
            .endOf('day')
            .format()
};
