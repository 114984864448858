import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import userRoles from 'src/constants/userRoles';
import getHeaderString from 'src/util/getHeaderString';
import resultsPerPageOptions from 'src/constants/resultsPerPageOptions';

async function getHeaders() {
    const roles = store.getState().user.roles;
    const {
        salesPayrollHeaders,
        GMPayrollHeaders,
        payrollHeaders
    } = await import('src/rootComponents/Commissions/headers');
    let headers = [];
    if (roles.includes(userRoles.admin) || roles.includes(userRoles.payroll))
        headers = payrollHeaders;
    else if (
        roles.includes(userRoles.gm) ||
        roles.includes(userRoles.orderSupportManager)
    )
        headers = GMPayrollHeaders;
    else if (roles.includes(userRoles.sales)) headers = salesPayrollHeaders;

    return headers;
}

export default async function(req, context) {
    const { branch, email } = context.profile;
    const TableRows = (await import('src/util/tableRows')).default;
    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;
    const roles = store.getState().user.roles;
    const headers = await getHeaders();
    const resultsPerPage =
        req.query.resultsPerPage || resultsPerPageOptions[0].toString();
    req.query = {
        ...req.query,
        resultsPerPage
    };

    const localHeaders = localStorage.getItem('commissionHeaders');

    if (!req.query.headers) {
        const headerString =
            localHeaders ||
            getHeaderString(
                headers.filter(header => {
                    return header.static == null && !header.defaultHidden;
                })
            );
        req.query = { ...req.query, headers: headerString };
    }
    localStorage.setItem('commissionHeaders', req.query.headers);

    // Set default for # of pay periods to display
    let payPeriodURL = '';
    if (!req.query.payPeriods) {
        req.query = roles.includes(userRoles.sales)
            ? { ...req.query, payPeriods: '12' }
            : { ...req.query, payPeriods: '1' };
    }
    payPeriodURL = `lastPayPeriods=${req.query.payPeriods}`;

    // Set default sort
    if (!req.query.sort) {
        const defaultSort = headers.find(header => header.primaryKey).value;
        const defaultAsc = false;
        req.query = { ...req.query, sort: defaultSort, asc: defaultAsc };
    }

    let requestURL = 'Payroll/Summary';

    // Build request url based on role / number of arguments
    if (
        !roles.includes(userRoles.admin) &&
        !roles.includes(userRoles.payroll)
    ) {
        if (
            roles.includes(userRoles.gm) ||
            roles.includes(userRoles.orderSupportManager)
        )
            requestURL += `?salespersonBranch=${branch}`;
        else if (roles.includes(userRoles.sales))
            requestURL += `?BeneficiaryUserId=${email}`;
        if (req.query.payPeriods) requestURL += '&' + payPeriodURL;
    } else if (req.query.payPeriods) requestURL += '?' + payPeriodURL;

    const data = await store.dispatch(getDataWithCachedRoutes(requestURL));

    const queryParams = new QueryParams(req.query);

    const tableRows = new TableRows(
        data.data,
        headers,
        resultsPerPage,
        queryParams
    );

    tableRows
        .filterByLockedStatus()
        .filterBySearch()
        .getSortedRows();

    return {
        tableRows,
        context,
        headers,
        queryParams
    };
}
