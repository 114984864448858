import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import resultsPerPageOptions from 'src/constants/resultsPerPageOptions';

export default async function(req, context) {
    const TableRows = (await import('src/util/tableRows')).default;
    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;
    const headers = (await import('src/rootComponents/VendorSpiffs/headers'))
        .default;
    const resultsPerPage =
        req.query.resultsPerPage || resultsPerPageOptions[0].toString();
    req.query = {
        ...req.query,
        resultsPerPage
    };

    if (!req.query.sort) {
        const defaultSort = headers.find(header => header.primaryKey).value;
        const defaultAsc = false;
        req.query = { ...req.query, sort: defaultSort, asc: defaultAsc };
    }

    const vendorSpiffsRequestURL = `/VendorSPIFF/GetAllUserSPIFFs`;
    const vendorSpiffsQuery = await store.dispatch(
        getDataWithCachedRoutes(vendorSpiffsRequestURL)
    );
    const payPeriodsRequestURL = `/Payroll/PayPeriodsIds`;
    const payPeriodIdsQuery = await store.dispatch(
        getDataWithCachedRoutes(payPeriodsRequestURL)
    );

    // set payPeriodIds in store so dropdown values can be accessed in addSpiffForm via icons and vendorSpiffs page
    const payPeriodIds = await payPeriodIdsQuery.data.map(payPeriod => {
        let newObj = {};
        newObj['value'] = payPeriod.id;
        newObj['label'] = payPeriod.description;
        return newObj;
    });

    const queryParams = new QueryParams(req.query);

    const tableRows = new TableRows(
        vendorSpiffsQuery.data,
        headers,
        resultsPerPage,
        queryParams
    );

    tableRows.filterBySearch().getSortedRows();

    return {
        tableRows,
        context,
        headers,
        queryParams,
        payPeriodIds
    };
}
