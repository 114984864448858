import store from 'src/store';

// Enables users to access an invoice object without worrying about if it's the
// `head` from invoiceDetail or the single letter keys from `/invoices`
// Check spec in `__util__/tests` for usage
export default function safeAccessInvoice(invoice, key) {
    if (!invoice) return null;
    const { invoiceLetterMap } = store.getState().app;

    const reverse = {};

    Object.keys(invoiceLetterMap).forEach(key => {
        reverse[invoiceLetterMap[key]] = key;
    });

    return (
        invoice[key] ||
        invoice[invoiceLetterMap[key]] ||
        invoice[invoiceLetterMap[key && key[0].toUpperCase() + key.slice(1)]] ||
        invoice[reverse[key]]
    );
}
