import actions from '../actions/user';
import { handleActions } from 'redux-actions';

export const userInitialState = {
    gapiLoading: true,
    roles: null,
    details: null
};

const reducerMap = {
    [actions.initUserStore]: state => {
        return {
            ...state,
            gapiLoading: true
        };
    },
    [actions.clearRoles]: state => {
        return {
            ...state,
            gapiLoading: false,
            roles: null
        };
    },
    [actions.setRoles]: (state, action) => {
        return {
            ...state,
            gapiLoading: false,
            roles: action.payload
        };
    },
    [actions.setDetails]: (state, action) => {
        return {
            ...state,
            details: action.payload
        };
    }
};

const reducer = handleActions(reducerMap, userInitialState);
export default reducer;
