export default function decodeJWT(jwt) {
    // Remove `Bearer` and the signature
    let jwtParts = jwt
        .replace('Bearer ', '')
        .split('.')
        .splice(0, 2);
    jwtParts = jwtParts.map(part => JSON.parse(atob(part)));
    return {
        head: jwtParts[0],
        payload: jwtParts[1]
    };
}
