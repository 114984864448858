import dateRanges from 'src/constants/dateRanges';
import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import getHeaderString from 'src/util/getHeaderString';
import resultsPerPageOptions from 'src/constants/resultsPerPageOptions';

export default async function(req) {
    const InvoiceHeaders = (await import('src/rootComponents/Home/headers'))
        .default;
    const TableRows = (await import('src/util/tableRows')).default;
    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;

    let rollingDateRange = req.query.rollingDateOption;

    let startDate = req.query.startDate;
    let endDate = req.query.endDate;

    if (rollingDateRange) {
        let range = Object.values(dateRanges).find(
            range => range.value === rollingDateRange
        );
        if (range) {
            startDate = range.start.toString();
            endDate = range.end.toString();
            req.query = {
                ...req.query,
                startDate,
                endDate
            };
        }
    }

    const resultsPerPage =
        req.query.resultsPerPage || resultsPerPageOptions[0].toString();
    req.query = {
        ...req.query,
        resultsPerPage
    };
    const localHeaders = localStorage.getItem('invoiceHeaders');

    if (!startDate || !endDate) {
        startDate = dateRanges.defaultDateRange.start;
        endDate = dateRanges.defaultDateRange.end;
        req.query = {
            ...req.query,
            startDate,
            endDate
        };
    }

    // Set initial table headers for Home page
    if (!req.query.headers) {
        const headerString =
            localHeaders ||
            getHeaderString(
                InvoiceHeaders.filter(header => {
                    return header.static == null && !header.defaultHidden;
                })
            );
        req.query = { ...req.query, headers: headerString };
    }
    localStorage.setItem('invoiceHeaders', req.query.headers);

    let queryHeaders;

    let headers = InvoiceHeaders.filter(header => {
        // Make sure headers exist as a string
        if (req.query && typeof req.query.headers === 'string') {
            queryHeaders = req.query.headers.split(',');
            if (queryHeaders.includes(header.value) || header.static) {
                return header;
            }
        }
    });

    headers.sort((a, b) =>
        // sort the headers to be in the same order of the query params
        queryHeaders.indexOf(a.value) > queryHeaders.indexOf(b.value) ? 1 : -1
    );

    headers = [
        headers.find(header => header.first),
        ...headers.filter(item => !item.first)
    ];
    headers = [
        ...headers.filter(item => !item.last),
        headers.find(header => header.last)
    ];

    // ^ ensure that the status icons/action icon headers are always first/last

    // these headers are used in the dropdown to show/hide headers.
    InvoiceHeaders.sort((a, b) =>
        queryHeaders.indexOf(a.value) > queryHeaders.indexOf(b.value) ? 1 : -1
    );

    if (!req.query.sort) {
        const defaultSort = InvoiceHeaders.find(header => header.primaryKey)
            .value;
        const defaultAsc = false;
        req.query = { ...req.query, sort: defaultSort, asc: defaultAsc };
    }

    const queryParams = new QueryParams(req.query);

    const paramString = Object.entries(queryParams._query)
        .map(
            ([key, value]) =>
                `${key.includes('Date') ? key + 'Unix' : key}=${value}`
        )
        .join('&');

    let requestUrl = `Invoice/?${paramString}`;

    let data = await store.dispatch(getDataWithCachedRoutes(requestUrl));

    const rows = data ? data.data : [];

    const tableRows = new TableRows(
        rows,
        InvoiceHeaders,
        resultsPerPage,
        queryParams
    );
    tableRows.filterBySearch().getSortedRows();
    return {
        tableRows,
        queryParams,
        orderedHeaders: headers,
        InvoiceHeaders
    };
}
