export default [
    {
        label: 'Not Fully Paid - Salespeople',
        value: {
            rows: ['SalesPersonBranch', 'SalesPersonId'],
            rowOrder: 'key_a_to_z',
            cols: ['IsFullyPaid'],
            vals: ['IsFullyPaid'],
            colOrder: 'key_a_to_z',
            aggregatorName: 'Count',
            rendererName: 'Table Heatmap',
            valueFilter: {
                IsFullyPaid: { True: true },
                SalesPersonBranch: { null: true },
                SalesPersonId: { null: true }
            }
        }
    },
    {
        label: 'Top Customer Groups by Branch',
        value: {
            rows: ['CustomerGroup'],
            rowOrder: 'value_z_to_a',
            cols: ['SalesPersonBranch'],
            vals: ['TotalSaleAmount'],
            colOrder: 'value_z_to_a',
            aggregatorName: 'Sum',
            rendererName: 'Stacked Column Chart',
            valueFilter: {
                SalesPersonBranch: { null: true },
                CustomerGroup: { null: true },
                SalesPersonId: { null: true }
            }
        }
    },
    {
        label: 'Not Fully Paid - Customer Groups',
        value: {
            rows: ['CustomerGroup', 'IsFullyPaid'],
            vals: ['Count'],
            rowOrder: 'value_z_to_a',
            rendererName: 'Table Col Heatmap',
            valueFilter: {
                IsFullyPaid: { True: true },
                CustomerGroup: { null: true }
            }
        }
    },
    {
        label: 'Top Salespeople by Commission Made',
        value: {
            rows: ['SalesPersonId'],
            vals: ['CommissionPayout'],
            aggregatorName: 'Sum',
            rowOrder: 'value_z_to_a',
            rendererName: 'Table',
            valueFilter: {
                SalesPersonId: { null: true }
            }
        }
    }
];
