import React from 'react';

import { DropdownSelect } from 'fronds/Controllers';

function DropdownSelectWrapper(props) {
    const colourStyles = {
        menuPortal: styles => ({
            ...styles,
            zIndex: 80
        }),
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: isDisabled
                    ? null
                    : isFocused && isSelected
                    ? 'var(--dropdown-focused-dark-blue)'
                    : isFocused
                    ? 'var(--dropdown-focused-blue)'
                    : isSelected
                    ? 'var(--dropdown-selected-blue)'
                    : null
            };
        }
    };

    return (
        <DropdownSelect
            menuPortalTarget={document.body}
            styles={colourStyles}
            {...props}
        />
    );
}

export default DropdownSelectWrapper;
