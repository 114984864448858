import React from 'react';
import tableActions from 'src/store/actions/table';
import store from 'src/store';
import actions from 'src/store/actions/app';
import classes from './messages.css';
import { func, string } from 'prop-types';
import navigation from 'src/util/navigation';
import RollingDates from './rollingDates';
import ApproveOverride from 'src/components/OverrideModal/approveOverride';
import DeclineOverride from 'src/components/OverrideModal/declineOverride';
import { dateFormat } from 'src/util/numberFormats';
const { dispatch } = store;
import { Button } from 'fronds/Controllers';

export function Controls(props) {
    const {
        submit,
        cancel = () => dispatch(actions.setConfirmationDialogue(null)),
        submitText = 'Continue'
    } = props;
    return (
        <div className={classes.controls}>
            <Button onClick={() => cancel()}>Cancel</Button>
            <Button onClick={() => submit()}>{submitText}</Button>
        </div>
    );
}

Controls.propTypes = {
    submit: func,
    cancel: func,
    submitText: string
};

export const closeOverride = row => {
    const { selectedRow } = store.getState().table;
    const { invoiceLetterMap } = store.getState().app;

    return {
        title: 'Confirmation',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: (
            <div className={classes.closeOverride}>
                <p>
                    You will lose the current override for invoice #
                    {selectedRow[invoiceLetterMap.InvoiceNumber]} if you
                    proceed.
                </p>
                <Controls
                    submit={() => {
                        dispatch(actions.setConfirmationDialogue(null));
                        if (row) {
                            dispatch(tableActions.setSelectedRow(row));
                        } else {
                            dispatch(actions.closeOverride());
                        }
                    }}
                />
            </div>
        )
    };
};

export const newRuleForm = (formContent, title) => {
    return {
        title: title,
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const newUserForm = formContent => {
    return {
        title: 'Add New User',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const newSpiffForm = formContent => {
    return {
        title: 'Create New Vendor SPIFF',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const editSpiffForm = formContent => {
    return {
        title: 'Edit Vendor SPIFF',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const newFlagForm = formContent => {
    return {
        title: 'Create New Flag',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const newBranchForm = formContent => {
    return {
        title: 'Create New Branch',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const notesModal = formContent => {
    return {
        title: 'Invoice Notes',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const deleteSpiffConfirmation = formContent => {
    return {
        title: 'Delete this SPIFF?',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const deleteBranchConfirmation = formContent => {
    return {
        title: 'Delete this Branch?',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const deleteRuleConfirmation = formContent => {
    return {
        title: 'Delete this rule?',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const deleteOverrideConfirmation = action => {
    return {
        title: 'Cancel Override Request',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: (
            <div className={classes.closeOverride}>
                <p>Are you sure you want to cancel this override request?</p>
                <Controls submit={action} />
            </div>
        )
    };
};

export const declineOverrideConfirmation = (declineOverrideRequest, row) => {
    return {
        title: 'Decline Override Request',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: (
            <div className={classes.closeOverride}>
                <DeclineOverride
                    row={row}
                    declineOverrideRequest={declineOverrideRequest}
                />
            </div>
        )
    };
};

export const approveOverrideMessage = (action, requestedDollarAmount, row) => {
    return {
        title: 'Approve Override Request',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: (
            <div className={classes.closeOverride}>
                <ApproveOverride
                    selectedRow={row}
                    requestedValue={requestedDollarAmount}
                />
            </div>
        )
    };
};

export const lockPayPeriodConfirmation = action => {
    return {
        title: 'Lock Pay Period',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: (
            <div className={classes.closeOverride}>
                <p>Are you sure you want to lock this pay period?</p>
                <Controls submit={action} />
            </div>
        )
    };
};

export const shareReportMessage = queryParams => {
    return {
        title: 'Share Report',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: (
            <div className={classes.closeOverride}>
                <RollingDates
                    queryParams={queryParams}
                    pathname={'/invoices/'}
                />
            </div>
        )
    };
};

export const exportPayPeriodConfirmation = (
    payPeriodDescription,
    formContent
) => {
    return {
        title: `Export Pay Period ${payPeriodDescription}`,
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: formContent
    };
};

export const confirmRouteMessage = path => {
    return {
        title: 'Unsaved changes',
        onClose: () => dispatch(actions.setConfirmationDialogue(null)),
        children: (
            <div className={classes.closeOverride}>
                <p>
                    You have unsaved changes. You may lose data if you navigate
                    away from this page.
                </p>
                <Controls
                    submit={() => {
                        dispatch(actions.setConfirmationDialogue(null));
                        dispatch(actions.setConfirmRouteChange(false));
                        navigation.navigate(path);
                    }}
                />
            </div>
        )
    };
};

export const viewNotesMessage = row => {
    const {
        requestNotes,
        dispositionExplanation,
        beneficiaryUserId,
        requestTimeUtcUnix,
        dispositionUserId,
        dispositionTimeUtcUnix
    } = row;

    return {
        title: `Override Notes`,
        onClose: () => {
            dispatch(actions.setConfirmationDialogue(null));
        },
        children: (
            <div className={classes.closeOverride}>
                <div>
                    <div className={classes.notesHeader}>
                        {beneficiaryUserId}
                    </div>
                    <div>{dateFormat(requestTimeUtcUnix)}</div>
                    <p>{requestNotes ? `"${requestNotes}"` : 'N/A'}</p>
                </div>
                {dispositionUserId ? (
                    <div>
                        <div className={classes.notesHeader}>
                            {dispositionUserId}
                        </div>
                        <div>{dateFormat(dispositionTimeUtcUnix)}</div>
                        <p>
                            {dispositionExplanation
                                ? `"${dispositionExplanation}"`
                                : 'N/A'}
                        </p>
                    </div>
                ) : null}
                <div className={classes.singleControl}>
                    <Button
                        onClick={() =>
                            dispatch(actions.setConfirmationDialogue(null))
                        }
                    >
                        OK
                    </Button>
                </div>
            </div>
        )
    };
};
