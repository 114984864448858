import React, { useState } from 'react';
import dateRanges from 'src/constants/dateRanges';
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';
import { useNavigation } from 'react-navi';
import useCopy from 'src/util/useCopy';
import Copy from 'feather/copy';
import Check from 'feather/check';
import classes from './rollingDates.css';
import store from 'src/store';
import actions from 'src/store/actions/app';
import { Controls } from './messages';
import { string } from 'prop-types';

const { dispatch } = store;

function RollingDates({ pathname }) {
    let navigation = useNavigation();

    const [value, setValue] = useState(window.location.href);
    const [modifiedQueryParams, setModifiedQueryParams] = useState(pathname);
    const [copied, copyToClipboard] = useCopy();

    const dateRangeSelect = dateRange => {
        // take the query params, but set the rolling date option to the selected value
        // we don't set url params here because we don't want to actually change the current query for the user
        let modifiedQueryParams = navigation.lastRoute.url.query;
        modifiedQueryParams.rollingDateOption = dateRange.value;

        // create the query string
        var queryString = new URLSearchParams(modifiedQueryParams).toString();

        // create a full link to the app with the new query string
        let urlToCopy = `${window.location.origin}${navigation.lastRoute.url.pathname}?${queryString}`;

        // set the modified query params, which the user may use to navigate the the new query
        setModifiedQueryParams(modifiedQueryParams);
        setValue(urlToCopy);
    };

    let dateRangesArr = Object.values(dateRanges).map(range => {
        return {
            value: range.value,
            label: range.text
        };
    });

    let noneOpt = { value: '', label: 'None' };
    dateRangesArr.unshift(noneOpt);

    return (
        <div className={classes.rollingDates}>
            <div className={classes.shareValue}>
                <span>{`${
                    value.length > 60 ? value.substring(0, 60) + '...' : value
                }`}</span>
                <span className={classes.copyIcons}>
                    {copied.includes(value) ? (
                        <>
                            copied
                            <Check />
                        </>
                    ) : (
                        <Copy onClick={() => copyToClipboard(value)} />
                    )}
                </span>
            </div>
            <div className={classes.rollingDateSelect}>
                <span>Use rolling date range?</span>
                <DropdownSelectWrapper
                    listItems={dateRangesArr}
                    onSelectionChange={dateRangeSelect}
                />
            </div>

            <Controls
                submit={() => {
                    dispatch(actions.setConfirmationDialogue(null));
                    navigation.navigate({
                        pathname: pathname,
                        query: { ...modifiedQueryParams }
                    });
                }}
                submitText={'View now'}
            />
        </div>
    );
}

RollingDates.propTypes = {
    pathname: string.isRequired
};

export default RollingDates;
