import React from 'react';
import {
    Award,
    BarChart,
    DollarSign,
    File,
    Flag,
    Folder,
    List,
    Settings,
    TrendingUp,
    User,
    Users
} from 'react-feather';

const navMenuOptions = {
    invoices: {
        label: 'Invoices',
        url: '/invoices/',
        icon: <List />,
        exact: false
    },
    projects: {
        label: 'Projects',
        url: '/projects/',
        icon: <Folder />,
        exact: false
    },
    reports: {
        label: 'Reports',
        url: '/reports/',
        icon: <User />,
        exact: false
    },
    commissions: {
        label: 'Commissions',
        url: '/commissions/',
        icon: <BarChart />,
        exact: false
    },
    commissionsBranch: {
        label: 'Commissions',
        url: '/commissions/branch-summary/',
        icon: <BarChart />,
        exact: false,
        activeUrl: '/commissions/'
    },
    overrides: {
        label: 'Overrides',
        url: '/overrides/',
        icon: <Award />,
        exact: false
    },
    commissionRules: {
        label: 'Commission Rules',
        url: '/commission-rules/',
        icon: <DollarSign />,
        exact: false
    },
    flagRules: {
        label: 'Flag Rules',
        url: '/flag-rules/',
        icon: <Flag />,
        exact: false
    },
    flagged: {
        label: 'Flagged Invoices',
        url: '/flagged/',
        icon: <Flag />
    },
    salespeople: {
        label: 'Salespeople',
        url: '/salespeople/',
        icon: <Users />,
        exact: false
    },
    analytics: {
        label: 'Analytics',
        url: '/analytics/',
        icon: <TrendingUp />,
        exact: false
    },
    vendorSpiffs: {
        label: 'Vendor SPIFFs',
        url: '/vendor-spiffs/',
        icon: <File />,
        exact: false
    },
    branchSettings: {
        label: 'Branch Settings',
        url: '/branch-settings/',
        icon: <Settings />,
        exact: false
    }
};

const navMenuSales = [
    navMenuOptions.invoices,
    navMenuOptions.projects,
    navMenuOptions.commissions,
    navMenuOptions.overrides,
    navMenuOptions.flagged,
    navMenuOptions.analytics,
    navMenuOptions.vendorSpiffs
];

const navMenuGM = [
    navMenuOptions.invoices,
    navMenuOptions.projects,
    navMenuOptions.commissions,
    navMenuOptions.overrides,
    navMenuOptions.flagged,
    navMenuOptions.analytics,
    navMenuOptions.vendorSpiffs
];

const navMenuPayroll = [
    navMenuOptions.commissionsBranch,
    navMenuOptions.invoices,
    navMenuOptions.projects,
    navMenuOptions.vendorSpiffs
];

const navMenuAdmin = [
    navMenuOptions.invoices,
    navMenuOptions.projects,
    navMenuOptions.commissions,
    navMenuOptions.overrides,
    navMenuOptions.commissionRules,
    navMenuOptions.flagRules,
    navMenuOptions.flagged,
    navMenuOptions.salespeople,
    navMenuOptions.analytics,
    navMenuOptions.vendorSpiffs,
    navMenuOptions.branchSettings
];

const navMenuAll = Object.keys(navMenuOptions).map(key => {
    return navMenuOptions[key];
});

export const navMenuLinks = {
    sales: navMenuSales,
    gm: navMenuGM,
    payroll: navMenuPayroll,
    admin: navMenuAdmin,
    dev: navMenuAll,
    orderSupportManager: navMenuGM
};
