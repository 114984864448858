import actions from '../actions/rule';
import { handleActions } from 'redux-actions';

export const initialState = {
    treeStatus: [],
    isProcessing: false
};

const reducerMap = {
    [actions.setTreeStatus]: (state, action) => {
        return { ...state, treeStatus: action.payload };
    },
    [actions.setIsProcessing]: (state, action) => {
        return { ...state, isProcessing: action.payload };
    }
};

const reducer = handleActions(reducerMap, initialState);
export default reducer;
