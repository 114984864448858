import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import resultsPerPageOptions from 'src/constants/resultsPerPageOptions';
import { request } from 'src/util/request';

export default async function(req, context) {
    const TableRows = (await import('src/util/tableRows')).default;
    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;
    const { default: headers } = await import(
        'src/rootComponents/CommissionsBranchSummary/headers'
    );
    // Set default for # of pay periods to display
    let payPeriodURL = '';
    if (!req.query.payPeriods) req.query = { ...req.query, payPeriods: 1 };
    payPeriodURL = `lastPayPeriods=${req.query.payPeriods}`;

    // Set default sort
    if (!req.query.sort) {
        const defaultSort = headers.find(header => header.primaryKey).value;
        const defaultAsc = false;
        req.query = { ...req.query, sort: defaultSort, asc: defaultAsc };
    }

    let requestURL = `Payroll/BranchSummary`;

    // Build request url based on role / number of arguments
    if (req.query.payPeriods) requestURL += '?' + payPeriodURL;

    const data = await store.dispatch(getDataWithCachedRoutes(requestURL));

    let clientIdsData = await request.get(`Payroll/GetClientIds`);
    const clientIds = clientIdsData.data.map((clientId, index) => {
        return {
            value: index,
            label: clientId
        };
    });

    let exportablePayPeriodsData = await request.get('Payroll/PayPeriodsIds');

    const exportablePayPeriods = exportablePayPeriodsData.data.map(period => {
        return {
            value: period.id,
            label: period.description
        };
    });

    const queryParams = new QueryParams(req.query);

    const defaultPageSizeIndex = 3;

    const tableRows = new TableRows(
        data.data,
        headers,
        resultsPerPageOptions[defaultPageSizeIndex],
        queryParams
    );

    tableRows
        .filterByLockedStatus()
        .filterBySearch()
        .getSortedRows();

    return {
        tableRows,
        context,
        headers,
        queryParams,
        exportablePayPeriods,
        clientIds,
        defaultPageSizeIndex
    };
}
