import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import resultsPerPageOptions from 'src/constants/resultsPerPageOptions';
import getHeaderString from 'src/util/getHeaderString';

export default async function(req /* context */) {
    const ProjectsHeaders = (
        await import('src/rootComponents/Projects/headers')
    ).default;
    const TableRows = (await import('src/util/tableRows')).default;
    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;

    const resultsPerPage =
        req.query.resultsPerPage || resultsPerPageOptions[0].toString();
    req.query = {
        ...req.query,
        resultsPerPage
    };

    //const localHeaders = localStorage.getItem('projectsHeaders');

    if (!req.query.headers) {
        const headerString =
            /* localHeaders || */
            getHeaderString(
                ProjectsHeaders.filter(header => {
                    return header.static == null && !header.defaultHidden;
                })
            );
        req.query = { ...req.query, headers: headerString };
    }
    localStorage.setItem('projectsHeaders', req.query.headers);

    let queryHeaders;

    let headers = ProjectsHeaders.filter(header => {
        // Make sure headers exist as a string
        if (req.query && typeof req.query.headers === 'string') {
            queryHeaders = req.query.headers.split(',');
            if (queryHeaders.includes(header.value) || header.static) {
                return header;
            }
        }
    });

    headers.sort((a, b) =>
        // sort the headers to be in the same order of the query params
        queryHeaders.indexOf(a.value) > queryHeaders.indexOf(b.value) ? 1 : -1
    );

    headers = [
        headers.find(header => header.first),
        ...headers.filter(item => !item.first)
    ];
    // Leaving this comment to prevent the potential gotcha if we ever add buttons back
    /* 
    headers = [
        ...headers.filter(item => !item.last),
        headers.find(header => header.last)
    ]; */

    // these headers are used in the dropdown to show/hide headers.
    ProjectsHeaders.sort((a, b) =>
        queryHeaders.indexOf(a.value) > queryHeaders.indexOf(b.value) ? 1 : -1
    );

    if (!req.query.sort) {
        const defaultSort = headers.find(header => header.primaryKey).value;
        const defaultAsc = false;
        req.query = { ...req.query, sort: defaultSort, asc: defaultAsc };
    }

    const queryParams = new QueryParams(req.query);

    const paramString = Object.entries(queryParams._query)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

    const projectsRequestUrl = `/Project?${paramString}`;
    const projectsResponse = await store.dispatch(
        getDataWithCachedRoutes(projectsRequestUrl)
    );

    const rows = projectsResponse ? projectsResponse.data : [];

    const tableRows = new TableRows(
        rows,
        ProjectsHeaders,
        resultsPerPage,
        queryParams
    );

    tableRows.filterBySearch().getSortedRows();

    return {
        tableRows,
        queryParams,
        orderedHeaders: headers,
        ProjectsHeaders
    };
}
