export default [
    {
        label: 'Commissions:',
        value: 1234.0
    },
    {
        label: 'Drawn:',
        value: 132.0
    },
    {
        label: 'Sold:',
        value: 11555.0
    },
    {
        label: 'Average GP:',
        value: 121.12
    },
    {
        label: 'Net Profit:',
        value: 1356.0
    }
];
