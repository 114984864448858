const rules = new Map();
rules.set('value', validateValue);
function validateValue({ value }) {
    const parsedValue = parseInt(value);
    if (Number.isNaN(parsedValue)) {
        return {
            isValid: false,
            message:
                'Please input only numbers. Start decimals with a "0", i.e. "0.5".'
        };
    }
    return { isValid: true };
}

export default rules;
