import React from 'react';
import appTitle from 'src/constants/appTitle';
import InvalidRoute from 'src/rootComponents/InvalidRoute';

export default function() {
    return {
        title: `Page Not Found ${appTitle}`,
        view: <InvalidRoute />
    };
}
