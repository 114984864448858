export default [
    {
        value: 0,
        label: 'Flagged'
    },
    {
        value: 1,
        label: 'Dismissed Flag'
    },
    {
        value: 2,
        label: 'Display All'
    }
];

export const displayAllFlagsIndex = 2;
