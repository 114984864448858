import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import getOverrideStatusDetail from 'src/util/getOverrideStatusDetail';
import resultsPerPageOptions from 'src/constants/resultsPerPageOptions';

export default async function(req) {
    const InvoiceLineHeaders = (
        await import('src/components/InvoiceDetail/headers')
    ).default;
    const TableRows = (await import('src/util/tableRows')).default;

    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;

    const statuses = await getOverrideStatusDetail();

    const requestURL = `Invoice/${req.query.id}/${req.query.orderNumber}/details`;
    const { data } = await store.dispatch(getDataWithCachedRoutes(requestURL));

    const resultsPerPage =
        req.query.resultsPerPage || resultsPerPageOptions[0].toString();
    req.query = {
        ...req.query,
        resultsPerPage
    };

    if (!req.query.sort) {
        const defaultSort = InvoiceLineHeaders.find(header => header.primaryKey)
            .value;
        const defaultAsc = true;
        req.query = { ...req.query, sort: defaultSort, asc: defaultAsc };
    }

    const queryParams = new QueryParams(req.query);

    const overrideRequests = data.overrideRequests.map(row => {
        const dispositionDetail = statuses[row.disposition];
        return {
            ...row,
            dispositionDetail
        };
    });

    const tableRows = new TableRows(
        data.invoiceLines,
        InvoiceLineHeaders,
        resultsPerPage,
        queryParams
    );

    tableRows.filterBySearch().getSortedRows();

    const invoiceDetail = {
        ...data,
        invoiceLines: tableRows,
        overrideRequests,
        queryParams
    };

    return invoiceDetail;
}
