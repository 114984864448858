import { getDataWithCachedRoutes } from 'src/store/actions/appAsyncActions';
import store from 'src/store';
import resultsPerPageOptions from 'src/constants/resultsPerPageOptions';
import statuses from 'src/constants/flagStatuses';

export default async function(req) {
    const InvoiceHeaders = (await import('src/rootComponents/Flagged/headers'))
        .default;
    const TableRows = (await import('src/util/tableRows')).default;
    const QueryParams = (await import('src/util/urlParams/queryParams'))
        .default;
    const payPeriodsRequestURL = `/Payroll/PayPeriodsIds`;
    const payPeriodIdsQuery = await store.dispatch(
        getDataWithCachedRoutes(payPeriodsRequestURL)
    );

    // set payPeriodIds in store so dropdown values can be accessed
    const payPeriodIds = await payPeriodIdsQuery.data.map(payPeriod => {
        let newObj = {};
        newObj['value'] = payPeriod.id;
        newObj['label'] = payPeriod.description;
        return newObj;
    });

    const resultsPerPage =
        req.query.resultsPerPage || resultsPerPageOptions[0].toString();
    req.query = {
        ...req.query,
        resultsPerPage
    };

    if (!req.query.sort) {
        const defaultSort = InvoiceHeaders.find(header => header.primaryKey)
            .value;
        const defaultAsc = false;
        req.query = { ...req.query, sort: defaultSort, asc: defaultAsc };
    }

    if (!req.query.payPeriods) {
        req.query = { ...req.query, payPeriods: payPeriodIds[0].value };
    }

    const queryParams = new QueryParams(req.query);

    const paramString = Object.entries(queryParams._query)
        .map(
            ([key, value]) =>
                `${
                    key.includes('payPeriods')
                        ? 'flagsBlockingPayPeriodId'
                        : key
                }=${value}`
        )
        .join('&');

    let requestURL = `Invoice/?${paramString}`;

    const data = await store.dispatch(getDataWithCachedRoutes(requestURL));
    const rows = data ? data.data : [];
    const tableRows = new TableRows(
        rows,
        InvoiceHeaders,
        resultsPerPage,
        queryParams
    );

    tableRows
        .filterByFlagStatus()
        .filterBySearch()
        .getSortedRows();
    return {
        tableRows,
        queryParams,
        statuses,
        InvoiceHeaders,
        payPeriodIds
    };
}
