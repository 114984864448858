import React from 'react';
import classes from './errorNetwork.css';
import { object, func } from 'prop-types';
import Alert from 'feather/alert-triangle';
import { BugReport } from 'fronds/Components';

import { Button } from 'fronds/Controllers';

function ErrorNetwork(props) {
    const { error, removeError } = props;
    async function retry() {
        const { default: nav } = await import('src/util/navigation');
        const url = nav.getCurrentValue().url;
        await nav.navigate({ ...url });
        removeError();
    }
    return (
        <div className={classes.root}>
            <div className={classes.headingWrapper}>
                <div className={classes.heading}>
                    <Alert />
                    <h2>{error.message}</h2>
                </div>
                <hr />
            </div>
            <div className={classes.messageWrapper}>
                <h3> Retry the request </h3>
                <p>Click this button to retry the request: </p>
                <Button onClick={() => retry()}>Retry request</Button>
                <h3>If you see this message again...</h3>
                <p> Submit a bug report </p>
                <BugReport />
            </div>
        </div>
    );
}

ErrorNetwork.propTypes = {
    error: object,
    removeError: func
};

export default ErrorNetwork;
